import React, { useState, useEffect } from "react";
// import Navbar from "../navbar";
import "./ProductList.css";
import Newsletter from "../newsletter";
import Footer from "../footer";
// import womanShoe2 from "../images/imagesWoman/womanShoe2.jpg";
// import {
//   FavoriteBorder,
//   SearchOutlined,
//   ShoppingCartOutlined,
//   StarRate,
//   StarBorderRounded,
//   StarRounded,
// } from "@material-ui/icons";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router";
import Products from "../products";

import { useTranslation } from "react-i18next";
// import cookies from "js-cookie";

import CartService from "../../services/cart-service"; // for user

const ProductList = () => {
  const { t } = useTranslation();
  // const curLang = cookies.get("i18next") || "en";

  const location = useLocation();
  const cat = location.pathname.split("/")[2];
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState("newest");
  const [openBD, setOpenBD] = useState(false);
  const [shoplist, setShopList] = useState([]);

  const handleFilters = (e) => {
    const value = e.target.value;
    // if (value !== "xxx") {
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
    // }
  };

  // product api data
  useEffect(() => {
    setOpenBD(!openBD);
    CartService.getShopInfo(cat)
      .then((response) => {
        setShopList(response.data);
        setOpenBD(false);
      })
      .catch((e) => {
        console.log(e);
      });

    window.scrollTo(0, 0);
  }, [cat]);

  return (
    <>
      <div>
        {/* <Navbar /> */}
        {/* <h1 className="dresses"></h1> */}
        <div className="filterContainer">
          <div className="filter">
            <div className="filterText">{t("filter_products")} :</div>
            <select name="shop" id="" onChange={handleFilters}>
              <option value="">{t("shop")}</option>
              {shoplist?.map((s) => {
                return (
                  <option key={s.shopid} value={s.shopid}>
                    {s.shopname}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="filter">
            <div className="filterText">{t("sort_products")} :</div>
            <select onChange={(e) => setSort(e.target.value)}>
              <option value="newest">{t("newest")}</option>
              <option value="asc">{t("highest_price")}</option>
              <option value="desc">{t("lowest_price")}</option>
            </select>
          </div>
        </div>
        <Products cat={cat} filters={filters} sort={sort} opt="1" />

        <Newsletter />
        <Footer />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProductList;
