// import {
//   FavoriteBorder,
//   SearchOutlined,
//   Visibility,
//   ShoppingCartOutlined,
//   StarRate,
//   StarBorderRounded,
//   StarRounded,
// } from "@material-ui/icons";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import React, { Component } from "react";
import Slider from "react-slick";
import "./hotdealsSlider.css";

// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import { addProduct } from "../../src/redux/cartRedux";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../constant/constant";

import CartService from "../services/cart-service";
// import TokenService from "../services/token.service";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        marginRight: 40,
        background: "rgba(116, 225, 47, 0.644)",
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        marginLeft: 40,
        background: "rgba(116, 225, 47, 0.644)",
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    />
  );
}

const Hotdeals = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  // const [products, setProducts] = useState([]);
  // const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const curLang = cookies.get("i18next") || "en";
  const typu = useSelector((state) => state.user.typu);

  // const utyp = TokenService.getSessionUserType();

  useEffect(() => {
    CartService.getFeaturedCart(curLang)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [curLang]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 11000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClick = (id, opt) => {
    // dispatch(addProduct({...product,}))
    if (opt === "1") {
      navigate(`/product/${id}`);
    } else {
      navigate(`/shops/${id}`);
    }
    // navigate("/address");
  };

  return (
    <div id="latestShoes" className="hot-container">
      {/* to prevent slider not working if less than 4 (cause setting = slidesToShow: 4,) */}
      {product.length >= 4 && (
        <>
          <h1>
            {t("latest")}{" "}
            <span style={{ color: "#278441" }}>
              {" "}
              {t("collection")}
            </span>
          </h1>
          <Slider {...settings}>
            {product.map((item) => (
              <div item={item} key={item.id}>
                <div className="Hot-images">
                  {/* <Link to={`/product/${item.id}`}> */}
                  <div style={{ backgroundColor: "#000" }}>
                    <img
                      src={`${PICTURE_SERVER}${item.pic}`}
                      alt=""
                      onClick={() => handleClick(item.id, "1")}
                    />
                  </div>
                  {/* <img src={`${PICTURE_SERVER}${item.pic}`} alt="" />
                  <div className="hotdeals-icons">
                    <Visibility className="hotIcons" />
                  </div> */}
                  <div className="productDesc">
                    <div
                      className="shopinfo"
                      onClick={() => handleClick(item.shop, "2")}
                    >
                      <StorefrontTwoToneIcon
                        style={{
                          fontSize: 13,
                          color: "orange",
                        }}
                      />{" "}
                      {item.shop_name}
                    </div>
                    <h2>{item.sku_name}</h2>
                    {/* <div style={{ marginLeft: "2%" }}>
                      <StarRounded
                        style={{ color: "gold", fontSize: "2.2rem" }}
                      />
                      <StarRounded
                        style={{ color: "gold", fontSize: "2.2rem" }}
                      />
                      <StarRounded
                        style={{ color: "gold", fontSize: "2.2rem" }}
                      />
                      <StarRounded
                        style={{ color: "gold", fontSize: "2.2rem" }}
                      />
                      <StarBorderRounded
                        style={{ color: "gold", fontSize: "2.2rem" }}
                      />
                    </div> */}
                    {typu === "A" ? (
                      <h2 style={{ marginBottom: "2%" }}>
                        RM {item.agent_promo_price} 
                      </h2>
                    ) : (
                      <h2 style={{ marginBottom: "2%" }}>
                        RM {item.promo_price}
                      </h2>
                    )}
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  );
};

export default Hotdeals;
