import React, { useState, useEffect } from "react";
import Footer from "../footer";
import "./cart.css";
import styled from "styled-components";
import { Add, Remove } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { updQty } from "../../redux/cartRedux";
import { logout } from "../../redux/apiCalls";

import undraw_empty_cart_co35 from "../../undraw_empty_cart_co35.png";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Modal,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../../constant/constant";

import UserCartService from "../../services/user-cart-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Cart = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  let navigate = useNavigate();
  const nbrfmt = new Intl.NumberFormat("en-GB", { minimumFractionDigits: 2 });

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [prodcnt, setProdCnt] = useState(0);
  const [typu, setUserType] = useState("");
  const [subamt, setSubamt] = useState(0);

  const [selectedCart, setSelectedCart] = useState([]);
  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "success",
  });

  const { vertical, horizontal, open, message, severity } = msg;

  const [openBD, setOpenBD] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const handleQuantity = (type, cartid, qty) => {
    setOpenBD(!openBD);
    if (type === "dec") {
      if (qty > 1) {
        const data = { cartid: cartid, typ: type };
        UserCartService.updateCartQty(curLang, data)
          .then((response) => {
            // console.log('res', response.data)
            setProducts(response.data.product);
            // setSubamt(+response.data.tot);
            updateCartAmountBySelectedCart(response.data.product);
            dispatch(updQty({ qty: response.data.qty }));
            setOpenBD(false);
          })
          .catch((e) => {
            if (e?.response?.status === 400) {
              logout(dispatch)
                .then(() => {
                  navigate("/login");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              console.log(e);
            }
          });
      } else {
        setOpenBD(false);
        setMsg({
          ...msg,
          open: true,
          message: `${t("clickDelete")}`,
          severity: "error",
        });
      }
    } else {
      const data = { cartid: cartid, typ: type };
      UserCartService.updateCartQty(curLang, data)
        .then((response) => {
          setProducts(response.data.product);
          // console.log('re+', response.data)
          // setSubamt(+response.data.tot);
          updateCartAmountBySelectedCart(response.data.product);
          dispatch(updQty({ qty: response.data.qty }));
          setOpenBD(false);
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            logout(dispatch)
              .then(() => {
                navigate("/login");
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log(e);
          }
        });
    }
  };

  const handleDelete = (cartid) => {
    // console.log('cart', typeof(cartid))
    setOpenBD(!openBD);
    UserCartService.deleteCartItem(cartid, curLang)
      .then((response) => {
        setSelectedCart([
          ...selectedCart.filter((v) => v !== cartid.toString()),
        ]);
        setProducts(response.data.product);
        setProdCnt(response.data.cnt);
        // setSubamt(+response.data.tot);
        updateCartAmountBySelectedCart(response.data.product);
        dispatch(updQty({ qty: response.data.qty }));
        setOpenBD(false);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  };

  const handleContinueShop = () => {
    navigate("/");
  };

  const handleCheckout = () => {
    navigate("/checkoutaddr", { state: selectedCart, replace: true });
  };

  const handleCheckBox = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedCart([...selectedCart, value]);
      updateCartAmount("add", +value);
    } else {
      setSelectedCart([...selectedCart.filter((v) => v !== value)]);
      updateCartAmount("dec", +value);
    }
  };

  const updateCartAmount = (opt, cartid) => {
    let amt = 0;
    let i = 0;
    for (i; i < products.length; i++) {
      let j = 0;
      for (j; j < products[i].cartlist.length; j++) {
        // so here need to also include latest state
        if (cartid === products[i].cartlist[j].id) {
          amt =
            products[i].cartlist[j].basket_qty *
            +products[i].cartlist[j].promo_price;
          if (opt === "add") {
            setSubamt(subamt + amt);
          } else {
            setSubamt(subamt - amt);
          }
        }
      }
    }
  };

  const updateCartAmountBySelectedCart = (data) => {
    let amt = 0;
    let i = 0;

    if (selectedCart.length > 0) {
      // console.log('fnc', selectedCart)
      for (i; i < data.length; i++) {
        // console.log('shop', products[i])
        let j = 0;
        for (j; j < data[i].cartlist.length; j++) {
          // this state only manage to keep previous data, not to the latest
          if (selectedCart.includes(data[i].cartlist[j].id.toString())) {
            // console.log("prod", data[i].cartlist[j]);
            amt +=
              data[i].cartlist[j].basket_qty * +data[i].cartlist[j].promo_price;
          }
        }
      }
      setSubamt(amt);
    }
  };

  useEffect(() => {
    UserCartService.getAvailableCart(curLang)
      .then((response) => {
        // console.log("res", response.data);
        setProducts(response.data.product);
        setProdCnt(response.data.cnt);
        setUserType(response.data.typu);
        // setSubamt(+response.data.tot);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  }, [curLang]);

  return (
    <>
      {products?.length === 0 ? (
        <div className="cart-empty">
          <img src={undraw_empty_cart_co35} alt="" />
        </div>
      ) : (
        <div className="cart-container">
          <div className="cart-wrapper">
            <h1>{t("yourBag")}</h1>
            <div className="top">
              <div className="topText">
                <h2>
                  {t("shopBasket")} ({prodcnt})
                </h2>
                {/* <h2>Your Wishlist (0)</h2> */}
                <Topbutton
                  type="filter"
                  lng={curLang}
                  onClick={handleContinueShop}
                >
                  {t("continueShopping")}
                </Topbutton>
              </div>
              {/* <Topbutton type="filter">CHECKOUT NOW</Topbutton> */}
            </div>
            <div className="bottom">
              <div className="info">
                {products.map((shop) => (
                  <div className="productContainer" key={shop.id}>
                    <div className="productHeader">
                      <div className="headerinfo">
                        <StorefrontTwoToneIcon
                          style={{
                            fontSize: 15,
                            color: "orange",
                          }}
                        />{" "}
                        {shop.shop_name}
                      </div>
                    </div>
                    {shop.cartlist.map((product) => (
                      <div className="product" key={product.id}>
                        <div className="productDetail">
                          <div className="checkBox">
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              id={product.id.toString()}
                              value={product.id.toString()}
                              onChange={handleCheckBox}
                            />
                          </div>
                          <img src={`${PICTURE_SERVER}${product.pic}`} alt="" />
                          <div className="details">
                            <div className="productName">
                              <b>{t("product")} :</b> {product.sku_name}
                            </div>
                            <div className="productID">
                              <b>{t("unitPrice")} : </b>
                              RM {nbrfmt.format(product.promo_price)}
                              {product.ori_price !== product.promo_price && (
                                <span className="oriPrice">
                                  RM {nbrfmt.format(product.ori_price)}
                                </span>
                              )}
                            </div>
                            {product.uom1 !== "std" && (
                              <div className="productSize">
                                <b>{t(product.uom1)} : </b>
                                {product.uom1desc}
                              </div>
                            )}
                            {product.uom2 !== "std" && (
                              <div className="productSize">
                                <b>{t(product.uom2)} : </b>
                                {product.uom2desc}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="priceDetail">
                          <div className="productAmountContainer">
                            <Remove
                              onClick={() =>
                                handleQuantity(
                                  "dec",
                                  product.id, // -this is cart id, not product id; based on API data
                                  product.basket_qty
                                )
                              }
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                cursor: "pointer",
                                border: "1px solid #ececec",
                                padding: "1px",
                              }}
                              disabled={product.basket_qty <= 1 ? true : false}
                            />
                            <div className="productAmount">
                              {product.basket_qty}
                            </div>
                            <Add
                              onClick={() =>
                                handleQuantity(
                                  "inc",
                                  product.id, // -this is cart id, not product id; based on API data
                                  product.basket_qty
                                )
                              }
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                cursor: "pointer",
                                border: "1px solid #ececec",
                                padding: "1px",
                              }}
                            />
                          </div>
                          <div className="productPrice">
                            RM{" "}
                            {nbrfmt.format(
                              product.promo_price * product.basket_qty
                            )}
                          </div>
                          <IconButton
                            onClick={() => handleDelete(product.id)}
                            color="error"
                            style={{ marginTop: 5 }}
                          >
                            <HighlightOffTwoToneIcon
                              style={{
                                fontSize: 20,
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="summary">
                <div className="summaryTitle">{t("orderSummary")}</div>
                {typu === "A" && (
                  <span className="summaryTitleAgent">{t("agent_price")} **</span>
                )}
                <div className="summaryItem">
                  <div className="summaryItemText">{t("subtotal")} :</div>
                  <div className="summaryItemText">
                    RM {nbrfmt.format(subamt)}
                  </div>
                </div>
                <div className="summaryItem">
                  <div className="summaryItemText">{t("shipping")} :</div>
                  <div className="summaryItemText">RM 0.00</div>
                </div>
                {/* <div className="summaryItem">
                  <div className="summaryItemText">Shipping Discount:</div>
                  <div className="summaryItemText">RM -5.90</div>
                </div> */}
                <div className="summaryItemBold">
                  <div className="summaryItemText">{t("total")}</div>
                  <div className="summaryItemText">
                    RM {nbrfmt.format(subamt)}
                  </div>
                </div>
                {user ? (
                  <>
                    {subamt > 0 && (
                      <button
                        className="summaryButton"
                        onClick={handleCheckout}
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                          letterSpacing: curLang === "cn" ? "1.5px" : "normal",
                        }}
                      >
                        {t("checkout")}
                      </button>
                    )}
                  </>
                ) : (
                  <div>
                    <h2 style={{ color: "grey" }}>
                      PLEASE LOGIN TO CHECKOUT{" "}
                      <span
                        style={{ textDecoration: "underline", fontSize: 12 }}
                      >
                        <Link to="/login">Login</Link>
                      </span>
                      │
                      <span
                        style={{ textDecoration: "underline", fontSize: 12 }}
                      >
                        <Link to="/signup">SignUp</Link>
                      </span>{" "}
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Cart;
