import React, { useState, useEffect, useRef } from "react";
// import Navbar from "../navbar";
import "./products.css";
import Newsletter from "../newsletter";
import Footer from "../footer";
import { Add, Remove } from "@material-ui/icons";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import { useNavigate, useParams } from "react-router-dom";

import { logout } from "../../redux/apiCalls";
import { updQty } from "../../redux/cartRedux";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../../constant/constant";

import CartService from "../../services/cart-service"; // for public
import UserCartService from "../../services/user-cart-service"; // for user

import {
  Box,
  Modal,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import SubLogin from "../component/sublogin";

import Slider from "react-slick";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Product = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  const user = useSelector((state) => state.user.currentUser);
  const typu = useSelector((state) => state.user.typu);
  let navigate = useNavigate();

  const [openBD, setOpenBD] = useState(true);
  const [product, setProduct] = useState({});
  const [uomlist, setUomlist] = useState([]);
  const [uom1list, setUom1list] = useState([]);
  const [uom2list, setUom2list] = useState([]);
  const [pricelist, setPricelist] = useState([]);
  const [piclist, setPiclist] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [addflg, setAddflg] = useState(false);
  // const [uom1desc, setUom1desc] = useState("");
  // const [uom2desc, setUom2desc] = useState("");
  const [minpromoprice, setMinPromoPrice] = useState("");
  const [maxpromoprice, setMaxPromoPrice] = useState("");
  const [minoriprice, setMinOriPrice] = useState("");
  const [maxoriprice, setMaxOriPrice] = useState("");
  const [minagenpromoprice, setMinAgenPromoPrice] = useState("");
  const [maxagenpromoprice, setMaxAgenPromoPrice] = useState("");
  const [minagenoriprice, setMinAgenOriPrice] = useState("");
  const [maxagenoriprice, setMaxAgenOriPrice] = useState("");
  const [pic, setPic] = useState("");
  const uom1 = useRef("");
  const uom2 = useRef("");

  const dispatch = useDispatch();

  // max slidesToShow = 6
  const [tot, setTot] = useState({
    big: 6,
    mid: 5,
    sm: 4,
  });
  const settings = {
    // dots: true,
    // className: "center",
    // centerMode: true,
    // centerPadding: "60px",
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: tot.big,
    slidesToScroll: 1,
    // beforeChange: (current, next) => {
    //   setTimeout(() => {
    //     setSlideState({ ...slideState, activeSlide: next });
    //   }, 10);
    // },
    afterChange: (current) => {
      // setSlideState({ activeSlide: current });
      setCurrentPic(current);
    },
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: tot.big,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: tot.mid,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: tot.sm,
        },
      },
    ],
  };

  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
  });

  const { vertical, horizontal, open, message, severity } = msg;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "transparent",
  };

  const setCurrentPic = (curslide) => {
    // console.log("pic", piclist[curslide]["file_name"]);
    setPic(piclist[curslide]["file_name"]);
  };

  useEffect(() => {
    CartService.getProductInfo(id, curLang)
      .then((response) => {
        // console.log("data", response.data);
        let totpic = response.data.pics.length;
        let tsm = 4;
        let tmid = 5;
        let tbig = 6;

        if (totpic === 5) {
          tbig = totpic;
        } else if (totpic <= 4) {
          tsm = totpic;
          tmid = totpic;
          tbig = totpic;
        }
        setTot({
          sm: tsm,
          mid: tmid,
          big: tbig,
        });

        setPic(response.data.item[0].pic);
        setProduct(response.data.item[0]);
        setUomlist(response.data.uom);
        setUom1list(response.data.uom1);
        setUom2list(response.data.uom2);
        setPricelist(response.data.price);
        setPiclist(response.data.pics);
        setMinPromoPrice(response.data.item[0].min_promo_price);
        setMaxPromoPrice(response.data.item[0].max_promo_price);
        setMinOriPrice(response.data.item[0].min_ori_price);
        setMaxOriPrice(response.data.item[0].max_ori_price);
        setMinAgenPromoPrice(response.data.item[0].agent_min_promo_price);
        setMaxAgenPromoPrice(response.data.item[0].agent_max_promo_price);
        setMinAgenOriPrice(response.data.item[0].agent_min_ori_price);
        setMaxAgenOriPrice(response.data.item[0].agent_max_ori_price);

        // let tmparr = [];
        // tmparr = uom1desclist.filter((u) => u.id === response.data.uom.uom1);
        // setUom1desc(tmparr[0].desc);
        // tmparr = [];
        // tmparr = uom2desclist.filter((u) => u.id === response.data.uom.uom2);
        // setUom2desc(tmparr[0].desc);

        if (response.data.uom.uom1 === "std") {
          // uom1.current = "std";
          uom1.current = response.data.uom1[0].uom1id;
        }
        if (response.data.uom.uom2 === "std") {
          // uom2.current = "std";
          uom2.current = response.data.uom2[0].uom2id
        }
        // console.log("res", response.data);
        setOpenBD(false);
      })
      .catch((e) => {
        console.log(e);
      });

    window.scrollTo(0, 0);
  }, [id, curLang]);

  const getPriceCombination = (suom1, suom2) => {
    // x - means ignore
    // console.log("suom12", suom1, suom2);
    let filteredprice = [];
    if (suom1 === "x") {
      if (uom1.current) {
        filteredprice = pricelist.filter((pri) => {
          return pri.uom1_id === uom1.current && pri.uom2_id === suom2;
        });
      }
    }
    if (suom2 === "x") {
      if (uom2.current) {
        filteredprice = pricelist.filter((pri) => {
          return pri.uom1_id === suom1 && pri.uom2_id === uom2.current;
        });
      }
    }

    if (suom2 !== "x") {
      uom2.current = suom2;
    }
    if (suom1 !== "x") {
      uom1.current = suom1;
    }
    // console.log("ref", uom1.current, uom2.current);
    // console.log("pri", filteredprice);
    if (filteredprice.length === 1) {
      setMinPromoPrice(filteredprice[0].promo_price);
      setMaxPromoPrice(filteredprice[0].promo_price);
      setMinOriPrice(filteredprice[0].ori_price);
      setMaxOriPrice(filteredprice[0].ori_price);

      setMinAgenPromoPrice(filteredprice[0].agent_promo_price);
      setMaxAgenPromoPrice(filteredprice[0].agent_promo_price);
      setMinAgenOriPrice(filteredprice[0].agent_ori_price);
      setMaxAgenOriPrice(filteredprice[0].agent_ori_price);
    } else {
      setMinPromoPrice(product.min_promo_price);
      setMaxPromoPrice(product.max_promo_price);
      setMinOriPrice(product.min_ori_price);
      setMaxOriPrice(product.max_ori_price);

      setMinAgenPromoPrice(product.agent_min_promo_price);
      setMaxAgenPromoPrice(product.agent_max_promo_price);
      setMinAgenOriPrice(product.agent_min_ori_price);
      setMaxAgenOriPrice(product.agent_max_ori_price);
    }
  };

  const addCart = (data) => {
    if (!uom1.current) {
      setMsg({
        ...msg,
        open: true,
        message: `${t(uomlist?.uom1)} ${t("uomempty")}`,
        severity: "error",
      });
      return;
    } else if (!uom2.current) {
      setMsg({
        ...msg,
        open: true,
        message: `${t(uomlist?.uom2)} ${t("uomempty")}`,
        severity: "error",
      });
      return;
    } else {
      setAddflg(true);
      UserCartService.addCart(data)
        .then((response) => {
          dispatch(updQty({ qty: response.data.cart }));
          setAddflg(false);
          setMsg({
            ...msg,
            open: true,
            message: `${t("addCartSuccess")}`,
            severity: "success",
          });
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            logout(dispatch)
              .then(() => {
                navigate("/login");
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log(e);
          }
        });
    }
  };

  //quantity of product
  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
  };

  // add quantity to cart
  const handleAddtoCart = () => {
    user
      ? addCart({
          id: product.id,
          uom1: uom1.current,
          uom2: uom2.current,
          price: minpromoprice,
          quantity: quantity,
        })
      : handleOpenModal(); // dispatch(addProduct({ ...product, quantity }));
  };

  const viewShop = (shopid) => {
    navigate(`/shops/${shopid}`);
  };

  return (
    <>
      <div className="Productcontainer">
        {/* <Navbar /> */}
        <div className="wrapper">
          <div className="ProductImageContainer">
            <div className="imgWrapper">
            {pic && <img src={`${PICTURE_SERVER}${pic}`} alt="" />}
            </div>

            {/* Slider */}
            <div className="displayItemContainer">
              {/* <div style={{ width: "400px" }}> */}
              <div className="item">
                {piclist.length && (
                  <Slider {...settings}>
                    {piclist.map((p) => {
                      return (
                        <div key={p.id}>
                          <img
                            src={`${PICTURE_SERVER}${p.file_name}`}
                            alt=""
                            style={{
                              border: "1px solid #ececec",
                              width: "60px",
                              height: "60px",
                            }}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
          </div>
          <div className="InfoContainer">
            <div
              className="headerinfo"
              onClick={() => viewShop(product?.shop_id)}
            >
              <StorefrontTwoToneIcon
                style={{
                  fontSize: 16,
                  color: "orange",
                }}
              />{" "}
              {product?.shop_name}
            </div>
            <h1>{product?.sku_name}</h1>
            <p className="info-desc">{product?.brief}</p>
            {typu === "A" && (
              <div className="info-desc1">{t("agent_price")} **</div>
            )}
            {typu === "A" ? (
              <div className="price">
                <div>
                  <span className="pricetag">RM</span> {minagenpromoprice}
                  {minagenoriprice !== minagenpromoprice && (
                    <span
                      style={{
                        padding: "0px 3px",
                        fontSize: "16px",
                        color: "#c1bfbf",
                        textDecoration: "line-through",
                      }}
                    >
                      RM {minagenoriprice}
                    </span>
                  )}
                </div>
                {minagenpromoprice !== maxagenpromoprice && (
                  <div>
                    <span className="pricetag"> - RM</span> {maxagenpromoprice}
                    {maxagenoriprice !== maxagenpromoprice && (
                      <span
                        style={{
                          padding: "0px 3px",
                          fontSize: "16px",
                          color: "#c1bfbf",
                          textDecoration: "line-through",
                        }}
                      >
                        RM {maxagenoriprice}
                      </span>
                    )}
                  </div>
                )}
                {/* product.promo_price */}
              </div>
            ) : (
              <div className="price">
                <div>
                  <span className="pricetag">RM</span> {minpromoprice}
                  {minoriprice !== minpromoprice && (
                    <span
                      style={{
                        padding: "0px 3px",
                        fontSize: "16px",
                        color: "#c1bfbf",
                        textDecoration: "line-through",
                      }}
                    >
                      RM {minoriprice}
                    </span>
                  )}
                </div>
                {minpromoprice !== maxpromoprice && (
                  <div>
                    <span className="pricetag"> - RM</span> {maxpromoprice}
                    {maxoriprice !== maxpromoprice && (
                      <span
                        style={{
                          padding: "0px 3px",
                          fontSize: "16px",
                          color: "#c1bfbf",
                          textDecoration: "line-through",
                        }}
                      >
                        RM {maxoriprice}
                      </span>
                    )}
                  </div>
                )}
                {/* product.promo_price */}
              </div>
            )}
            <div className="ProductfilterContainer">
              {uomlist?.uom1 !== "std" && (
                <div className="Productfilter">
                  <div className="filterTitle">{t(uomlist.uom1)}</div>
                  <select
                    className="filterSize"
                    onChange={(e) => getPriceCombination(e.target.value, "x")}
                  >
                    {/* <option value="">-</option> */}
                    {uom1list?.map((s) => {
                      return (
                        <option
                          className="filterSizeOption"
                          key={s.uom1id}
                          value={s.uom1id}
                        >
                          {s.uom1desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {uomlist?.uom2 !== "std" && (
                <div className="Productfilter">
                  <div className="filterTitle">{t(uomlist.uom2)}</div>
                  <select
                    className="filterSize"
                    onChange={(e) => getPriceCombination("x", e.target.value)}
                  >
                    {/* <option value="">-</option> */}
                    {uom2list?.map((s) => {
                      return (
                        <option
                          className="filterSizeOption"
                          key={s.uom2id}
                          value={s.uom2id}
                        >
                          {s.uom2desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className="addContainer">
              <div className="amountContainer">
                <Remove onClick={() => handleQuantity("dec")} />
                <div className="amount">{quantity}</div>
                <Add onClick={() => handleQuantity("inc")} />
              </div>
              <button
                onClick={handleAddtoCart}
                disabled={addflg ? true : false}
              >
                {t("addtoCart")}
              </button>
            </div>
          </div>
        </div>
        <div className="wrapperContainer">
          <h1>
            <span className="boxwrapper">{t("prodDetails")}</span>
          </h1>
          <div className="wrapperContent">
            {product?.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: product.content,
                }}
              ></div>
            )}
          </div>
        </div>
        <Newsletter />
        <Footer />
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SubLogin handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Product;
