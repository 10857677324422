import React from "react";
import Slider from "react-slick";
import "./slider.css";
import showcaseImageOne from "../frontend/images/imagesSlider/banner1.jpg";
import showcaseImageTwo from "../frontend/images/imagesSlider/banner2.jpeg";
import showcaseMobileOne from "../frontend/images/imagesSlider/mobbanner1.jpg";
import showcaseMobileTwo from "../frontend/images/imagesSlider/mobbanner2.jpg";
// import { BluetoothSearchingOutlined, ViewListOutlined } from "@material-ui/icons";
// import { useState } from "react";
// import {HashLink as Hlink } from 'react-router-hash-link'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        marginRight: 30,
        background: "grey",
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        marginLeft: 30,
        background: "grey",
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    />
  );
}

function SimpleSlider() {
  const settingmob = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // const [click, setClick] = useState(false)

  // const handleClick = () => setClick(!click)

  return (
    <div className="container">
      <div className="showcase">
        <Slider {...settings}>
          <div>
            <div className="image">
              <img src={showcaseImageOne} alt="" />
              {/* <img src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvcm0yMDhiYXRjaDgtMjUxLW5hcC10d2l0dGVyaGVhZGVyLTAxLmpwZw.jpg?s=4xTP6qGTJYm0eW5jpgWLBGFmUFKcMVizNgKoLVM35x8" alt="j" /> */}
            </div>
          </div>

          <div>
            <div className="image">
              <img src={showcaseImageTwo} alt="" />
              {/* <img src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvcm0yMDhiYXRjaDgtMjUxLW5hcC10d2l0dGVyaGVhZGVyLTAxLmpwZw.jpg?s=4xTP6qGTJYm0eW5jpgWLBGFmUFKcMVizNgKoLVM35x8" alt="j" /> */}
            </div>
          </div>
        </Slider>
      </div>

      <div className="mobileShowCase">
        <div className="mobileShowCase-container">
          <Slider {...settingmob}>
            <div>
              <img src={showcaseMobileOne} alt="" />
              {/* <img src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvcm0yMDhiYXRjaDgtMjUxLW5hcC10d2l0dGVyaGVhZGVyLTAxLmpwZw.jpg?s=4xTP6qGTJYm0eW5jpgWLBGFmUFKcMVizNgKoLVM35x8" alt="j" /> */}
            </div>
            <div>
              <img src={showcaseMobileTwo} alt="" />
              {/* <img src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvcm0yMDhiYXRjaDE0LXdhbi0wNS1sLmpwZw.jpg?s=mGNc-pFOoHDAXQ-TbLIrC0ems3vEYiRjeSZ2W1IMiJY" alt="j" /> */}
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default SimpleSlider;
