import React from 'react'
import Home from './frontend/pages/home'
import ProductList from './frontend/pages/productList'
import ByShopList from './frontend/pages/byshopList';
import './App.css'
import Product from './frontend/pages/product';
import Cart from './frontend/pages/cart';
import CheckoutAddr from './frontend/pages/checkoutaddr';
import CheckoutPymt from './frontend/pages/checkoutpymt';
import CheckoutPymtStatus from './frontend/pages/checkoutpymtstat';
import SignUp from './frontend/pages/signup';
import Login from './frontend/pages/login'
import Navbar from './frontend/navbar';
import Navbottom from './frontend/navbottom';
import AllProducts from './frontend/pages/AllProducts';
import Searchpage from './frontend/pages/searchpage';
import MobileMemberMenu from './frontend/member/mobmembernav';
import MemberProfile from './frontend/member/membprofile';
import MemberPassword from './frontend/member/membpswd';
import MemberAddress from './frontend/member/membaddress';
import MemberOrder from './frontend/member/memborder';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PhotoCredits from './frontend/pages/photoCredits';
import OrderDetail from './frontend/member/memborderDetail';
import ApplyRefundOrder from './frontend/member/membrefund';
import RefundOrderDetail from './frontend/member/membrefundDetail';

// import SimpleSlider from './frontend/slider';
// import { matchPath, useLocation, useParams } from 'react-router-dom';


function App() {
  const user = useSelector((state) => state.user.currentUser)


  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route path="/" element={<Home/>}/> */}
        <Route path="/home" exact element={<Home />} />
        <Route path="products/:category" element={<ProductList />} />
        <Route path="shops/:shopid" element={<ByShopList />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/mobmenu" exact element={<MobileMemberMenu />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/photoCredits" element={<PhotoCredits />} />
        <Route path="/search/:keyword" element={<Searchpage />} />
        <Route path="/AllProducts" element={< AllProducts />} />
        <Route path="/navbottom" element={< Navbottom />} />
        {/* member area */}
        <Route path="/signup/:ref" element={user ? <Navigate to="/" /> : <SignUp />} />
        <Route path="/login" element={user ? <Navigate to="/mobmenu" /> : <Login />} />
        <Route path="/editprofile" element={user ? <MemberProfile /> : <Navigate to="/login" />} />
        <Route path="/editpswd" element={user ? <MemberPassword /> : <Navigate to="/login" />} />
        <Route path="/address" element={user ? <MemberAddress /> : <Navigate to="/login" />} />
        <Route path="/order/:tab" element={user ? <MemberOrder /> : <Navigate to="/login" />} />
        <Route path="/orderdeta/:orderno" element={<OrderDetail />} />
        <Route path="/checkoutaddr" element={user ? <CheckoutAddr /> : <Navigate to="/login" />} />
        <Route path="/checkoutaddr/:orderno/:addrid" element={user ? <CheckoutAddr /> : <Navigate to="/login" />} />
        <Route path="/checkoutpymt" element={user ? <CheckoutPymt /> : <Navigate to="/login" />} />
        <Route path="/checkoutpymt/:orderno/:addrid/:opt" element={user ? <CheckoutPymt /> : <Navigate to="/login" />} />
        {/* <Route path="/checkoutstat" element={user ? <CheckoutPymtStatus /> : <Navigate to="/login" />} /> */}
        <Route path="/checkoutstat/:orderno" element={user ? <CheckoutPymtStatus /> : <Navigate to="/login" />} />
        <Route path="/applyrefund/:orderno" element={user ? <ApplyRefundOrder /> : <Navigate to="/login" />} />
        <Route path="/refunddetail/:orderno" element={user ? <RefundOrderDetail /> : <Navigate to="/login" />} />
      </Routes>
      <Navbottom />
    </Router>
  );
}

export default App;
