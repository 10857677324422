import React, { useEffect, useState } from "react";
import "./Products.css";
// import womanShoe2 from "./images/imagesWoman/womanShoe2.jpg";
// import {
//   FavoriteBorder,
//   SearchOutlined,
//   ShoppingCartOutlined,
//   StarRate,
//   StarBorderRounded,
//   StarRounded,
// } from "@material-ui/icons";
import FeaturedProductsProp from "./ProductsProp";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cookies from "js-cookie";

import CartService from "../services/cart-service";

const Products = ({ cat, filters, sort, opt }) => {
  // const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  const typu = useSelector((state) => state.user.typu);

  const [products, setProducts] = useState([]);
  // const [featuredCollection, setFeaturedCollection] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // API call for product list
  useEffect(() => {
    if (opt === "1") {
      // cat = category links
      CartService.getProdByCategory(cat, curLang)
        .then((response) => {
          // console.log("prod", response.data);
          setProducts(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // cat = shopid
      CartService.getProdByShop(cat, curLang)
        .then((response) => {
          // console.log("prod", response.data);
          setProducts(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [curLang, cat, opt]);

  useEffect(() => {
    // console.log("cat", cat, "filter", filters, products);
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.sort_price - a.sort_price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.sort_price - b.sort_price)
      );
    }
  }, [sort]);

  return (
    <div className="featured-container">
      <div className="image-container">
        {filteredProducts.map((item) => (
          <FeaturedProductsProp item={item} typu={typu} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default Products;
