import React from "react";
import './signup.css'
import { signup } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

import { 
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
    CircularProgress,
    FormHelperText,
} from '@mui/material';

import cookies from "js-cookie";
import { Formik } from "formik";
import * as Yup from "yup";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const { ref } = useParams();
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  const dispatch = useDispatch()

  const initialValues = {
    email: "",
    fullname: "",
    mobileno: "",
    username: "",
    password: "",
  };

  const phone = [];
  phone['MY'] = /^[0][1][0-9]{8,11}$/

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid") + ":" + t("email"))
      .required(t("isRequired") + " : " + t("email")),
    fullname: Yup.string().required(t("isRequired") + " : " + t("fullname")),
    mobileno: Yup.string()
      .required(t("isRequired") + " : " + t("mobileno"))
      .matches(
        phone['MY'],
        t("invalid") + ":" + t("phone")
      ),
    username: Yup.string()
      .required(t("isRequired") + " : " + t("username"))
      .matches(
          /^[aA-zZ1-9]+$/,
          "No White Space And Special Character are Allowed"
        ),
    password: Yup.string()
      .min(6, t("Minimum 6 alphanumeric characters"))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,24}$/,
        t("Must Be In Alphanumeric characters")
      )
      .required(t("isRequired") + " : " + t("password")),
  });

    // const {isFetching, loginfail} = useSelector((state)=> state.user)

    return ( 
      <div className="signup-container">
        <div className="signup-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { props, setFieldError, setSubmitting }) => {
            setTimeout(() => {
              let newdata = { ...values, refid: ref }
             
              signup(dispatch, newdata).then((response) => {
                // console.log('login');
              })
              .catch((emsg) => {
                  if (emsg === "1") {
                    setFieldError(
                      "username",
                      `${t("existrecord")} : ${t("username")}`
                    );
                  } else if (emsg === "2") {
                    setFieldError(
                      "mobileno",
                      `${t("existrecord")} : ${t("mobileno")}`
                    );
                  } else if (emsg === "4") {
                    setFieldError(
                      "email",
                      `${t("existrecord")} : ${t("email")}`
                    );
                  } else if (emsg === "3") {
                    setFieldError(
                      "email",
                      `${t("invalid")} : ${t("referral_name")}`
                    );
                  }
                  setSubmitting(false);
              });
            }, 200);
          }}
        >

          {(formik) => (
          <Card sx={{ fontSize: "1.5rem", py: 3, px:2}}>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
              <Grid 
                container
              >
                <Grid item xs={12} sx={{mb:2}}>
                  <h1>{t("createAcct")}</h1>
                </Grid>
              </Grid>
              <Grid 
                container
                rowSpacing={3}
                spacing={2}
              >
                <Grid item xs={12} >
                  {/* <InputLabel htmlFor="email" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("email")}</InputLabel> */}
                  <TextField
                    placeholder={`${t("pleaseFillIn")}  :  ${t("email")}`}
                    id="email"
                    name="email"
                    variant="outlined"
                    label={t("email")}
                    InputProps={{
                    inputProps: { style: { fontSize: "1.5rem", padding: "1rem"}},
                    }}
                    InputLabelProps={{ style: { fontSize: "1.35rem", lineHeight:"1.25rem"} }}
                    fullWidth
                    {...formik.getFieldProps("email")}
                    error={
                    formik.touched.email && Boolean(formik.errors.email)
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                      <FormHelperText id="email" sx={{fontSize:"1.15rem", color: "#E0144C"}}>
                          {formik.errors.email}
                      </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} >
                  {/* <InputLabel htmlFor="fullname" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("fullname")}</InputLabel> */}
                  <TextField
                    placeholder={`${t("pleaseFillIn")}  :  ${t("fullname")}`}
                    id="fullname"
                    name="fullname"
                    variant="outlined"
                    label={t("fullname")}
                    InputProps={{
                    inputProps: { style: { fontSize: "1.5rem", padding: "1rem"}},
                    }}
                    InputLabelProps={{ style: { fontSize: "1.35rem", lineHeight:"1.25rem"} }}
                    fullWidth
                    {...formik.getFieldProps("fullname")}
                    error={
                    formik.touched.fullname && Boolean(formik.errors.fullname)
                    }
                  />
                  {formik.touched.fullname && formik.errors.fullname && (
                      <FormHelperText id="fullname" sx={{fontSize:"1.15rem", color: "#E0144C"}}>
                          {formik.errors.fullname}
                      </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <InputLabel htmlFor="mobileno" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("mobileno")}</InputLabel> */}
                  <TextField
                    placeholder={`01xxxxxxxx`}
                    id="mobileno"
                    name="mobileno"
                    variant="outlined"
                    label={t("mobileno")}
                    InputProps={{
                    inputProps: { style: { fontSize: "1.5rem", padding: "1rem"}},
                    }}
                    InputLabelProps={{ style: { fontSize: "1.35rem", lineHeight:"1.25rem"} }}
                    fullWidth
                    {...formik.getFieldProps("mobileno")}
                    error={
                    formik.touched.mobileno && Boolean(formik.errors.mobileno)
                    }
                  />
                  {formik.touched.mobileno && formik.errors.mobileno && (
                      <FormHelperText id="mobileno" sx={{fontSize:"1.15rem", color: "#E0144C"}}>
                          {formik.errors.mobileno}
                      </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid 
                container
                rowSpacing={3}
                spacing={2}
                sx={{mt:1}}
              >
                <Grid item xs={12} md={6}>
                  {/* <InputLabel htmlFor="username" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("username")}</InputLabel> */}
                  <TextField
                    placeholder={`${t("pleaseFillIn")}  :  ${t("username")}`}
                    id="username"
                    name="username"
                    variant="outlined"
                    label={t("username")}
                    InputProps={{
                    inputProps: { style: { fontSize: "1.5rem", padding: "1rem"}},
                    }}
                    InputLabelProps={{ style: { fontSize: "1.35rem", lineHeight:"1.25rem"} }}
                    fullWidth
                    {...formik.getFieldProps("username")}
                    error={
                    formik.touched.username && Boolean(formik.errors.username)
                    }
                  />
                  {formik.touched.username && formik.errors.username && (
                      <FormHelperText id="username" sx={{fontSize:"1.15rem", color: "#E0144C"}}>
                          {formik.errors.username}
                      </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <InputLabel htmlFor="password" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("password")}</InputLabel> */}
                  <TextField
                    placeholder={`${t("pleaseFillIn")}  :  ${t("password")}`}
                    type="password"
                    id="password"
                    name="password"
                    variant="outlined"
                    label={t("password")}
                    InputProps={{
                    inputProps: { style: { fontSize: "1.5rem", padding: "1rem"}},
                    }}
                    InputLabelProps={{ style: { fontSize: "1.35rem", lineHeight:"1.25rem"} }}
                    fullWidth
                    {...formik.getFieldProps("password")}
                    error={
                    formik.touched.password && Boolean(formik.errors.password)
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText id="password" sx={{fontSize:"1.15rem", color: "#E0144C"}}>
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                    <div className="agreement">
                        {curLang === 'en' && (<span>{t('signUpAgreement')} <b>{t('privacypolicy')}</b></span>)}
                        {curLang === 'cn' && (<span>{t('signUpAgreement')}</span>)}
                    </div>
                  <Button
                    variant="contained"
                    color="warning"
                    type="submit"
                    fullWidth
                    sx={{ p: 1, fontSize: "1.5rem" }}
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting && (
                      <CircularProgress size={28} color="success" />
                    )}
                    {!formik.isSubmitting && t("login")}
                  </Button>
                </Grid>
            </Grid>
            </form>
            </CardContent>
          </Card>
          )}
        </Formik>
        </div>
      </div>
    );
}
 
export default SignUp;