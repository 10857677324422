import React, { useState, useEffect } from "react";

import Footer from "../footer";

import "./checkout.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { logout } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import { Box, Modal, Stepper, Step, StepButton } from "@mui/material";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import AddAddr from "../component/address";
import ExistAddr from "../component/existingAddr";
import UserCartService from "../../services/user-cart-service"; // for user
import UserAddrService from "../../services/user-addr-service"; // for user
import UserOrderService from "../../services/user-order-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

export default function HorizontalNonLinearStepper() {
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  const { orderno, addrid } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  let selectedCart = "";
  location?.state ? (selectedCart = location.state) : (selectedCart = "");

  const nbrfmt = new Intl.NumberFormat("en-GB", { minimumFractionDigits: 2 });
  const steps = [t("selectAddress"), t("choosePymtMethod")];
  const [addrs, setAddr] = useState([]);
  const [defaddr, setDefAddr] = useState([]);
  const [subamt, setSubamt] = useState(0);
  const [shipamt, setShipamt] = useState(0);
  const [totamt, setTotamt] = useState(0);
  const [modalopt, setModalOpt] = useState("");

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCheckout = () => {
    if (orderno) {
      navigate(`/checkoutpymt/${orderno}/${addrid}/1`);
    } else {
      navigate(`/checkoutpymt`, { state: selectedCart, replace: true });
    }
  };

  const handleOpenModalAdd = () => {
    setModalOpt("1");
    handleOpenModal();
  };

  const handleOpenModalExist = () => {
    setModalOpt("2");
    handleOpenModal();
  };

  useEffect(() => {
    UserAddrService.view()
      .then((response) => {
        let alladdr = response.data.alladdr;
        setAddr(alladdr);
        // let defaddr = alladdr.filter((addr) => addr.default_addr === "Y");
        // setDefAddr(defaddr);
        if (addrid) {
          let defaddr = alladdr.filter((addr) => addr.id === parseInt(addrid));
          setDefAddr(defaddr);
        } else {
          let defaddr = alladdr.filter((addr) => addr.default_addr === "Y");
          setDefAddr(defaddr);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });

    if (orderno) {
      // for to Pay routing to this page that already have order no
      UserOrderService.getAmount(orderno)
        .then((response) => {
          setSubamt(response.data.prod_amount);
          setShipamt(response.data.delivery_amount);
          // setTaxamt(response.data.tax_amount);
          setTotamt(response.data.total);
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            logout(dispatch)
              .then(() => {
                navigate("/login");
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log(e);
          }
        });
    } else {
      // for newly created order
      UserCartService.getAmount({ "cartid": selectedCart })
        .then((response) => {
          setSubamt(response.data.prod);
          setShipamt(response.data.ship);
          // setTaxamt(response.data.tax);
          setTotamt(response.data.tot);
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            logout(dispatch)
              .then(() => {
                navigate("/login");
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log(e);
          }
        });
    }
  }, [orderno]);

  return (
    <>
      <div className="checkout-container">
        <div className="checkout-header-wrapper">
          <div className="stepper-container">
            <Stepper nonLinear activeStep={0}>
              {steps.map((label, index) => (
                <Step key={index} completed={false}>
                  <StepButton
                    color="inherit"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: "2.5rem",
                      },
                      "& .MuiStepIcon-text": {
                        fontSize: "1.5rem",
                      },
                      ".Mui-active": {
                        "& .MuiSvgIcon-root": {
                          color: "orange",
                        },
                      },
                    }}
                  >
                    <div style={{ fontSize: "1.5rem" }}>{label}</div>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className="checkout-wrapper">
          <div className="top">
            <div className="topText">
              <Topbutton
                type="filter"
                lng={curLang}
                onClick={handleOpenModalAdd}
              >
                {t("addNewAddr")}
              </Topbutton>
              <span style={{ paddingLeft: "1.25rem" }}>
                <Topbutton
                  type="filter"
                  lng={curLang}
                  onClick={handleOpenModalExist}
                >
                  {t("existingAddr")}
                </Topbutton>
              </span>
            </div>
          </div>
          <div className="bottom">
            <div className="info">
              <span style={{ fontSize: "1.35rem" }}>{t("deliveryto")} : </span>
              {defaddr === undefined || defaddr.length === 0 ? (
                <div className="product">
                  <div className="productDetail">
                    <div className="details">
                      <div className="productName">
                        <b>{t("selectAddr")} </b>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                defaddr.map((addr) => (
                  <div className="product" key={addr.id}>
                    <div className="productDetail">
                      <div className="details">
                        <div className="productName">
                          <b>{t("recipient")} :</b> {addr.receiver}
                        </div>
                        <div className="productName">
                          <b>{t("mobileno")} :</b> {addr.mobile}
                        </div>
                        <div className="productName">{addr.addr},</div>
                        <div className="productName">{addr.addr2},</div>
                        <div className="productName">
                          {addr.addr3 ? addr.addr3 : ""}
                        </div>
                        <div className="productName">
                          {addr.post_code} {addr.city}, {addr.state}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="summary">
              <div className="summaryTitle">{t("orderSummary")}</div>
              <div className="summaryItem">
                <div className="summaryItemText">{t("subtotal")} :</div>
                <div className="summaryItemText">
                  RM {nbrfmt.format(subamt)}
                </div>
              </div>
              <div className="summaryItem">
                <div className="summaryItemText">{t("shipping")} :</div>
                <div className="summaryItemText">
                  RM {nbrfmt.format(shipamt)}
                </div>
              </div>
              {/* <div className="summaryItem">
                  <div className="summaryItemText">Shipping Discount:</div>
                  <div className="summaryItemText">RM -5.90</div>
                </div> */}
              <div className="summaryItemBold">
                <div className="summaryItemText">{t("total")}</div>
                <div className="summaryItemText">
                  RM {nbrfmt.format(totamt)}
                </div>
              </div>
              {user ? (
                <button
                  className="summaryButton"
                  onClick={handleCheckout}
                  style={{
                    color: "white",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    letterSpacing: curLang === "cn" ? "1.5px" : "normal",
                    backgroundColor:
                      defaddr === undefined || defaddr.length === 0
                        ? "#ececec"
                        : "orange",
                  }}
                  disabled={
                    defaddr === undefined || defaddr.length === 0 ? true : false
                  }
                >
                  {t("continueCheckout")}
                </button>
              ) : (
                <div>
                  <h2 style={{ color: "grey" }}>
                    PLEASE LOGIN TO CHECKOUT{" "}
                    <span style={{ textDecoration: "underline", fontSize: 12 }}>
                      <Link to="/login">Login</Link>
                    </span>
                    │
                    <span style={{ textDecoration: "underline", fontSize: 12 }}>
                      <Link to="/signup">SignUp</Link>
                    </span>{" "}
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="checkout-modal">
          {modalopt === "1" ? (
            <AddAddr
              handleCloseModal={handleCloseModal}
              setAddr={setAddr}
              setDefAddr={setDefAddr}
              opt={""}
            />
          ) : (
            <ExistAddr
              handleCloseModal={handleCloseModal}
              setAddr={setAddr}
              addrs={addrs}
              setDefAddr={setDefAddr}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}
