import http from "../http-user";

const view = (lng, curpage, tab) => {
  const newdata = { "curlang": lng, "curpage": curpage, "tab": tab }
  return http.post(`/member/order/view`, newdata);
};

const viewById = (orderno, lng) => {
  return http.get(`/member/order/view/${orderno}/${lng}`);
};

const viewByIdAndDlvr = (orderno, lng) => {
  return http.get(`/member/order/viewanddlvr/${orderno}/${lng}`);
};

const getAmount = (orderno) => {
  return http.get(`/member/order/getamt/${orderno}`);
}

const updateOrder = (orderno, data) => {
  return http.put(`/member/order/updorder/${orderno}`, data);
}

const cancelItem = (itemid) => {
  return http.get(`/member/order/cancelitem/${itemid}`);
}

const cancelOrder = (orderno) => {
  return http.get(`/member/order/cancelorder/${orderno}`);
}

const confirmOrder = (orderno) => {
  return http.get(`/member/order/received/${orderno}`);
}

const getOrderPymt = (orderno) => {
  return http.get(`/member/order/chkpymt/${orderno}`);
}

const refundItem = (itemid) => {
  return http.get(`/member/refund/refunditem/${itemid}`);
}

const refundOrder = (data) => {
  return http.post(`/member/refund/refundorder`, data);
}

const viewRefundById = (orderno, lng) => {
  return http.get(`/member/refund/view/${orderno}/${lng}`);
};

const withdrawRefund = (orderno, lng) => {
  return http.get(`/member/refund/withdrawrefund/${orderno}/${lng}`);
}

const exportedObject = {
  view,
  viewById,
  viewByIdAndDlvr,
  getAmount,
  updateOrder,
  cancelItem,
  cancelOrder,
  confirmOrder,
  getOrderPymt,
  refundOrder,
  refundItem,
  viewRefundById,
  withdrawRefund,
};

export default exportedObject;
