import http from "../http-user";

const addCart = (data) => {
  return http.post("/member/cart/addcart", data);
};

const getAvailableCart = (lng) => {
  return http.get(`/member/cart/view/${lng}`);
};

const updateCartQty = (lng, data) => {
  return http.put(`/member/cart/update/${lng}`, data);
};

const deleteCartItem = (cartid, lng) => {
  return http.delete(`/member/cart/remove/${cartid}/${lng}`);
};

const getAmount = (cartid) => {
  return http.post(`/member/cart/totamt`, cartid);
};

const placeOrder = (data) => {
  return http.post("/member/order/addorder", data);
}

const exportedObject = {
  addCart,
  getAvailableCart,
  updateCartQty,
  deleteCartItem,
  getAmount,
  placeOrder,
};

export default exportedObject;
