import React, { useState, useEffect } from "react";

import Footer from "../footer";

import "./checkout.css";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/apiCalls";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Box,
  Modal,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
  Button,
  Stepper,
  Step,
  StepButton,
  Radio,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import AddAddr from "../component/address";
import ExistAddr from "../component/existingAddr";
import UserCartService from "../../services/user-cart-service"; // for user
import UserAddrService from "../../services/user-addr-service"; // for user
import UserOrderService from "../../services/user-order-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function HorizontalNonLinearStepper() {
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  const { orderno, addrid, opt } = useParams(); // opt = from checkoutaddr, maybe user change address
  let navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  let selectedCart = "";
  location?.state ? (selectedCart = location.state) : (selectedCart = "");
  // console.log("selcart", selectedCart);

  const nbrfmt = new Intl.NumberFormat("en-GB", { minimumFractionDigits: 2 });
  const steps = [t("selectAddress"), t("choosePymtMethod")];
  const dispatch = useDispatch();
  const [addrs, setAddr] = useState([]);
  const [defaddr, setDefAddr] = useState([]);
  const [subamt, setSubamt] = useState(0);
  const [shipamt, setShipamt] = useState(0);
  const [totamt, setTotamt] = useState(0);
  const [modalopt, setModalOpt] = useState("");
  const [pymtopt, setPymtOpt] = useState("0");

  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const { vertical, horizontal, open, message } = msg;

  const [openBD, setOpenBD] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModalAdd = () => {
    setModalOpt("1");
    handleOpenModal();
  };

  const handleOpenModalExist = () => {
    setModalOpt("2");
    handleOpenModal();
  };

  const handlePymtOpt = (opt) => {
    setPymtOpt(opt);
  };

  const handlePrevious = () => {
    if (orderno) {
      navigate(`/checkoutaddr/${orderno}/${addrid}`);
    } else {
      navigate(`/checkoutaddr`, { state: selectedCart, replace: true });
    }
  };

  const handlePlaceOrder = () => {
    setOpenBD(!openBD);
    setTimeout(() => {
      const data = { pymtopt, curLang, cartid: selectedCart };

      if (orderno) {
        const dataord = { ...data, opt };
        UserOrderService.updateOrder(orderno, dataord)
          .then((response) => {
            // console.log("updateorder", response.data);
            // still need to touch up
            toIpay88(response.data);
            setOpenBD(false);
          })
          .catch((e) => {
            if (e?.response?.status === 400) {
              logout(dispatch)
                .then(() => {
                  navigate("/login");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              setMsg({ ...msg, open: true, message: `${e.message}` });
            }
          });
      } else {
        UserCartService.placeOrder(data)
          .then((response) => {
            // console.log("placeorder", response.data);
            // dispatch(updQty({ qty: 0 }));
            // still need to touch up
            toIpay88(response.data);
            setOpenBD(false);
          })
          .catch((e) => {
            if (e?.response?.status === 400) {
              logout(dispatch)
                .then(() => {
                  navigate("/login");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              setMsg({ ...msg, open: true, message: `${e.message}` });
            }
          });
      }
    }, 200);
  };

  const toIpay88 = (data) => {
    // redirect to ipay88
    let tempForm = document.createElement("form");

    // tempForm.action = data.httpurl;
    // tempForm.action = `http://localhost:81/shhutils/inqrpts/ipaydata.php?ordn=${data.payno}`;
    tempForm.action = `https://ninjahouseware.my/shhutils/inqrpts/ipaydata.php?ordn=${data.payno}`;
    tempForm.method = "post";
    document.body.appendChild(tempForm);

    // ipay88 sample
    // let config = response.data.ipay.param_value;
    // const obj = JSON.parse(config);
    // for (let key in obj) {
    //   console.log(key, obj[key]);
    // }

    // let params = data.params;
    // for (let key in params) {
    let tempInput = document.createElement("input");
    tempInput.type = "hidden";
    // tempInput.name = key;
    // tempInput.value = params[key]; //the　parameter　of　method　in　the　code　of　DispatchAction.
    //console.log(tempInput.name + " " + tempInput.value)
    tempInput.name = "ResponseURL";
    tempInput.value = "http://dev.skyhaha.com/user-center/uc-pymt-rcvd";

    tempForm.appendChild(tempInput);
    // }

    tempForm.submit();
    document.body.removeChild(tempForm);
  };

  // opt = 1 (from checkoutaddr); opt = 2 (memberorderDetail);
  // opt = 1 -> maybe customer will change address, system will auto update to default addr
  // opt = 2 -> from orderdetail, so no chance customer to change addr
  useEffect(() => {
    UserAddrService.view()
      .then((response) => {
        let alladdr = response.data.alladdr;
        setAddr(alladdr);
        if (addrid && opt === "2") {
          let defaddr = alladdr.filter((addr) => addr.id === parseInt(addrid));
          setDefAddr(defaddr);
        } else {
          let defaddr = alladdr.filter((addr) => addr.default_addr === "Y");
          setDefAddr(defaddr);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });

    if (orderno) {
      UserOrderService.getAmount(orderno)
        .then((response) => {
          setSubamt(response.data.prod_amount);
          setShipamt(response.data.delivery_amount);
          // setTaxamt(response.data.tax_amount);
          setTotamt(response.data.total);
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            logout(dispatch)
              .then(() => {
                navigate("/login");
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log(e);
          }
        });
    } else {
      UserCartService.getAmount({ cartid: selectedCart })
        .then((response) => {
          setSubamt(response.data.prod);
          setShipamt(response.data.ship);
          // setTaxamt(response.data.tax);
          setTotamt(response.data.tot);
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            logout(dispatch)
              .then(() => {
                navigate("/login");
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log(e);
          }
        });
    }
  }, [orderno, addrid]);

  return (
    <>
      <div className="checkout-container">
        <div className="checkout-header-wrapper">
          <div className="stepper-container">
            <Stepper nonLinear activeStep={1}>
              {steps.map((label, index) => (
                <Step key={index} completed={false}>
                  <StepButton
                    color="inherit"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: "2.5rem",
                      },
                      "& .MuiStepIcon-text": {
                        fontSize: "1.5rem",
                      },
                      ".Mui-active": {
                        "& .MuiSvgIcon-root": {
                          color: "orange",
                        },
                      },
                    }}
                  >
                    <div style={{ fontSize: "1.5rem" }}>{label}</div>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className="checkout-wrapper">
          <div className="bottom">
            <div className="info">
              {defaddr === undefined || defaddr.length === 0 ? (
                <div className="product">
                  <div className="productDetail">
                    <div className="details">
                      <div className="productName">
                        <b>{t("selectAddr")} </b>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                defaddr.map((addr) => (
                  <div className="product" key={addr.id}>
                    <div className="productDetail">
                      <div className="details">
                        <div className="productName">
                          <b>{t("deliveryto")} :</b> {addr.receiver}{" "}
                          <b>{t("mobileno")} :</b> {addr.mobile}
                        </div>
                        <div className="productName">{addr.addr},</div>
                        <div className="productName">{addr.addr2},</div>
                        <div className="productName">
                          {addr.addr3 ? addr.addr3 : ""}
                        </div>
                        <div className="productName">
                          {addr.post_code} {addr.city}, {addr.state}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
              <div className="product">
                <div className="bankDetail">
                  <div className="details">
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "2"}
                        onChange={() => handlePymtOpt("2")}
                        value="2"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/ccard.png" alt="" />
                      {t("creditcard")}
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "6"}
                        onChange={() => handlePymtOpt("6")}
                        value="6"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/6-mbb.png" alt="" />
                      Maybank2U
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "8"}
                        onChange={() => handlePymtOpt("8")}
                        value="8"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/8-alz.png" alt="" />
                      Alliance Online
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "10"}
                        onChange={() => handlePymtOpt("10")}
                        value="10"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/10-abmb.png" alt="" />
                      AmOnline
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "14"}
                        onChange={() => handlePymtOpt("14")}
                        value="14"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/14-rhb.png" alt="" />
                      RHB Online
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "15"}
                        onChange={() => handlePymtOpt("15")}
                        value="15"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/15-hlb.png" alt="" />
                      Hong Leong Online
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "20"}
                        onChange={() => handlePymtOpt("20")}
                        value="20"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/20-cimb.png" alt="" />
                      CIMB Click
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "31"}
                        onChange={() => handlePymtOpt("31")}
                        value="31"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/31-pbb.png" alt="" />
                      Public Bank Online
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "102"}
                        onChange={() => handlePymtOpt("102")}
                        value="102"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/102-bkr.png" alt="" />
                      Bank Rakyat Internet Banking
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "103"}
                        onChange={() => handlePymtOpt("103")}
                        value="103"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/103-affin.png" alt="" />
                      Affin Online
                    </div>
                  </div>
                </div>
                <div className="bankDetail">
                  <div className="details">
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "124"}
                        onChange={() => handlePymtOpt("124")}
                        value="124"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/124-bsn.png" alt="" />
                      BSN Online
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "134"}
                        onChange={() => handlePymtOpt("134")}
                        value="134"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/134-bimb.png" alt="" />
                      Bank Islam
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "152"}
                        onChange={() => handlePymtOpt("152")}
                        value="152"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/152-uob.png" alt="" />
                      UOB
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "166"}
                        onChange={() => handlePymtOpt("166")}
                        value="166"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/166-muamalat.png" alt="" />
                      Bank Muamalat
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "167"}
                        onChange={() => handlePymtOpt("167")}
                        value="167"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/167-ocbc.png" alt="" />
                      OCBC
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "168"}
                        onChange={() => handlePymtOpt("168")}
                        value="168"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/168-scb.png" alt="" />
                      Standard Chartered Bank
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "198"}
                        onChange={() => handlePymtOpt("198")}
                        value="198"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/198-hsbc.png" alt="" />
                      HSBC Online Banking
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "199"}
                        onChange={() => handlePymtOpt("199")}
                        value="199"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/199-kuwait.png" alt="" />
                      Kuwait Finance House
                    </div>
                    <div className="desc">
                      <Radio
                        checked={pymtopt === "210"}
                        onChange={() => handlePymtOpt("210")}
                        value="210"
                        name="radio-buttons"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <img src="/ipay88BankLogo/210-boost.png" alt="" />
                      Boost Wallet
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="summary">
              <div className="summaryTitle">{t("orderSummary")}</div>
              <div className="summaryItem">
                <div className="summaryItemText">{t("subtotal")} :</div>
                <div className="summaryItemText">
                  RM {nbrfmt.format(subamt)}
                </div>
              </div>
              <div className="summaryItem">
                <div className="summaryItemText">{t("shipping")} :</div>
                <div className="summaryItemText">
                  RM {nbrfmt.format(shipamt)}
                </div>
              </div>
              {/* <div className="summaryItem">
                  <div className="summaryItemText">Shipping Discount:</div>
                  <div className="summaryItemText">RM -5.90</div>
                </div> */}
              <div className="summaryItemBold">
                <div className="summaryItemText">{t("total")}</div>
                <div className="summaryItemText">
                  RM {nbrfmt.format(totamt)}
                </div>
              </div>
              {user ? (
                <>
                  {totamt > 0 && (
                    <button
                      className="summaryButton"
                      onClick={handlePlaceOrder}
                      style={{
                        color:
                          defaddr === undefined ||
                          defaddr.length === 0 ||
                          pymtopt === "0"
                            ? "gray"
                            : "white",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        letterSpacing: curLang === "cn" ? "1.5px" : "normal",
                        backgroundColor:
                          defaddr === undefined ||
                          defaddr.length === 0 ||
                          pymtopt === "0"
                            ? "#ececec"
                            : "#278441",
                      }}
                      disabled={
                        defaddr === undefined ||
                        defaddr.length === 0 ||
                        pymtopt === "0"
                          ? true
                          : false
                      }
                    >
                      {t("placeOrder")}
                    </button>
                  )}
                  <Topbutton
                    type=""
                    lng={curLang}
                    onClick={handlePrevious}
                    style={{ marginTop: "1rem", width: "100%" }}
                  >
                    {t("Back")}
                  </Topbutton>
                </>
              ) : (
                <div>
                  <h2 style={{ color: "grey" }}>
                    PLEASE LOGIN TO CHECKOUT{" "}
                    <span style={{ textDecoration: "underline", fontSize: 12 }}>
                      <Link to="/login">Login</Link>
                    </span>
                    │
                    <span style={{ textDecoration: "underline", fontSize: 12 }}>
                      <Link to="/signup">SignUp</Link>
                    </span>{" "}
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="checkout-modal">
          {modalopt === "1" ? (
            <AddAddr handleCloseModal={handleCloseModal} setAddr={setAddr} />
          ) : (
            <ExistAddr
              handleCloseModal={handleCloseModal}
              setAddr={setAddr}
              addrs={addrs}
              setDefAddr={setDefAddr}
            />
          )}
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {t("failPlaceOrder")} {message}
        </Alert>
      </Snackbar>
    </>
  );
}
