import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import DraftsIcon from "@mui/icons-material/Drafts";
// import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AddHomeIcon from "@mui/icons-material/AddHome";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import { Card, CardContent, Grid } from "@mui/material";

import "./mobmembernav.css";
import { logout } from "../../redux/apiCalls";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function NestedList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  let navigate = useNavigate();

  const handleClick = (opt) => {
    switch (opt) {
      case "1":
        navigate("/editprofile");
        break;
      case "2":
        navigate("/order/0");
        break;
      case "3":
        navigate("/");
        break;
      case "4":
        navigate("/");
        break;
      case "5":
        navigate("/address");
        break;
      case "6":
        navigate("/editpswd");
        break;
      case "7":
        logout(dispatch);
        navigate("/login");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <div className="mobmenu-container">
      <div className="mobmenu-wrapper">
        <Grid container>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <div className="signin-title">
              <AccountCircleIcon color="success" sx={{ fontSize: "8rem" }} />
              <h1>{t("welcome")}</h1>
            </div>
          </Grid>
        </Grid>
        <Card sx={{ fontSize: "1.5rem" }}>
          <CardContent>
            <h1>
              {t("hi")}, {user}
            </h1>
            <ListItemButton onClick={() => handleClick("1")}>
              <ListItemIcon>
                <PersonIcon color="success" sx={{ fontSize: "2rem" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("myProfile")}
              />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick("2")}>
              <ListItemIcon>
                <ShoppingCartIcon color="success" sx={{ fontSize: "2rem" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("myOrders")}
              />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick("3")}>
              <ListItemIcon>
                <AccountBalanceWalletIcon
                  color="success"
                  sx={{ fontSize: "2rem" }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("myWallet")}
              />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick("4")}>
              <ListItemIcon>
                <LocalAtmIcon color="success" sx={{ fontSize: "2rem" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("myVouchers")}
              />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick("5")}>
              <ListItemIcon>
                <AddHomeIcon color="success" sx={{ fontSize: "2rem" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("myAddress")}
              />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick("6")}>
              <ListItemIcon>
                <LockOpenIcon color="success" sx={{ fontSize: "2rem" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("changePassword")}
              />
            </ListItemButton>
            <ListItemButton onClick={() => handleClick("7")}>
              <ListItemIcon>
                <LogoutIcon color="success" sx={{ fontSize: "2rem" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.6rem" }}
                primary={t("logout")}
              />
            </ListItemButton>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
