import * as React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "./sublogin.css";

import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";

export default function GenerateQrCode({ handleCloseModal, qrcode }) {
  const { t } = useTranslation();

  const downloadQR = () => {
    const canvas = document.getElementById("genqr");  // this is a canvas
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `mkckqrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="login-wrapper">
      <Card sx={{ fontSize: "1.5rem", py: 3, px: 2 }}>
        <CardHeader
          sx={{
            "& .MuiCardHeader-title": {
              fontSize: "20px",
              fontWeight: 300,
            },
            "& .MuiCardHeader-action": {
              "& .MuiSvgIcon-root": {
                fontSize: "25px",
              },
            },
          }}
          action={
            <IconButton onClick={() => handleCloseModal()}>
              <HighlightOffIcon />
            </IconButton>
          }
          title={t("qrcode")}
        />
        <CardContent>
          <Grid container>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={12}
            >
              <QRCodeCanvas
                id="genqr"
                value={qrcode}
                size={300}
                level={"M"}
                includeMargin={true}
              />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={12}
              mt={2}
            >
              <Button
                variant="text"
                sx={{ p: 1, fontSize: "1.5rem" }}
                onClick={downloadQR}
              >
                download qr code
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
