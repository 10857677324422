import React from "react";
import { publicRequest, userRequest } from "../requestMethods";
import { loginStart, loginSuccess, loginFailure, logoutNow, resetMsg, relogin } from "./userRedux"
import {
    getProductStart,
    getProductSuccess,
    getProductFailure,
} from "./productRedux";
import { removeProduct, updQty, resetQty } from "./cartRedux";

import AuthService from "../services/auth-service";
import TokenService from "../services/token.service";

// user = {email, password}
export const login = async (dispatch, user) => {
    dispatch(loginStart());
    // try {
    //     const res = await publicRequest.post("signup/login", user);
    //     dispatch(loginSuccess(res.data))

    // } catch (err) {
    //     dispatch(loginFailure())
    // }
    return new Promise((resolve, reject) => {
        AuthService.login(user.username, user.password)
            .then((response) => {
                dispatch(loginSuccess(response.data))
                dispatch(updQty({ "qty": response.data.cart }))
                TokenService.setUser(response.data)
                resolve();
            })
            .catch((e) => {
                dispatch(loginFailure())
                reject();
            });
    });
}

export const signup = async (dispatch, user) => {
    // dispatch(loginStart());
    // try {
    //     const res = await publicRequest.post("signup/register", user);

    //     dispatch(loginSuccess(res.data))

    // } catch (err) {
    //     dispatch(loginFailure())
    // }
    return new Promise((resolve, reject) => {
        dispatch(loginStart());
        AuthService.signup(user)
            .then((response) => {
                dispatch(loginSuccess(response.data))
                resolve();
            })
            .catch((e) => {
                dispatch(loginFailure())
                reject(e.response.data.response);
            });
    });
}


export const logout = async (dispatch) => {
    // dispatch(logoutNow());
    // dispatch(resetQty());

    return new Promise((resolve, reject) => {
        AuthService.logout()
            .then(() => {
                dispatch(logoutNow());
                dispatch(resetQty());
                resolve();
            })
            .catch((e) => {
                reject(e.response.data.response);
            });
    });
}


export const removeProductNow = (id) => async (dispatch) => {
    dispatch(removeProduct(id));
}


export const getProducts = (keyword = " ") => async (dispatch) => {
    dispatch(getProductStart())
    try {
        const res = await publicRequest.get(`/products?keyword=${keyword}`);
        dispatch(getProductSuccess(res.data.products))
    } catch (err) {
        dispatch(getProductFailure());
    }
}

// export const redologin = (token) => async (dispatch) => {
//     dispatch(relogin(token));
// }
