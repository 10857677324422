import React, { useState, useEffect } from "react";

import Footer from "../footer";

import "./membaddress.css";
import styled from "styled-components";

import { logout } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Modal,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  CardHeader,
  Avatar,
  AvatarGroup,
  Backdrop,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import AddAddr from "../component/address";
import UserAddrService from "../../services/user-addr-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function HorizontalNonLinearStepper() {
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [openBD, setOpenBD] = useState(false);
  const [addrs, setAddr] = useState([]);
  const [totrec, setTotRec] = useState(0);
  const [modalopt, setModalOpt] = useState("");
  const [openCfscr, setopenCfscr] = useState(false);
  const [delid, setDelid] = useState("");

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = msg;

   const style = {
     position: "absolute",
     top: "50%",
     left: "50%",
     transform: "translate(-50%, -50%)",
     width: "100vw",
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     bgcolor: "transparent",
   };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const handleOpenModalAdd = () => {
    setModalOpt("");
    handleOpenModal();
  };

  const handleUpdateAddr = (addrid) => {
    setModalOpt(addrid.toString());
    handleOpenModal();
  };

  const handleShipping = (addrid) => {
    setOpenBD(!openBD);
    UserAddrService.setdefault({ addrid })
      .then((response) => {
        let alladdr = response.data;
        setAddr(alladdr);
        setOpenBD(false);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  };

  const handleBilling = (addrid) => {
    setOpenBD(!openBD);
    UserAddrService.setdefbill({ addrid })
      .then((response) => {
        let alladdr = response.data;
        setAddr(alladdr);
        setOpenBD(false);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  };

  const handleCfmDelete = (delid) => {
    setDelid(delid);
    setopenCfscr(true);
  };

  const handleCloseDlg = (e) => {
    if (e.target.value === "Y") {
      setTimeout(() => {
        UserAddrService.remove(delid)
          .then((response) => {
            let alladdr = response.data.alladdr;
            setAddr(alladdr);
            setTotRec(response.data.tot);
            // setopenCfscr(false);
            setMsg({ ...msg, open: true });
          })
          .catch((e) => {
            if (e?.response?.status === 400) {
              logout(dispatch)
                .then(() => {
                  navigate("/login");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              console.log(e);
            }
          });
      }, 200);
    }
    setopenCfscr(false);
  };

  useEffect(() => {
    UserAddrService.view()
      .then((response) => {
        let alladdr = response.data.alladdr;
        setAddr(alladdr);
        setTotRec(response.data.tot);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  }, []);

  return (
    <>
      <div className="memaddr-container">
        <div className="memaddr-wrapper">
          <h1>{t("myAddress")}</h1>
          <div className="top">
            <div className="topText">
              <span style={{ paddingLeft: "1.25rem" }}>
                {totrec < 10 && (
                  <Topbutton
                    type="filter"
                    lng={curLang}
                    onClick={handleOpenModalAdd}
                  >
                    {t("addNewAddr")}
                  </Topbutton>
                )}
              </span>
            </div>
          </div>
          <div className="bottom">
            <Grid container spacing={2}>
              {addrs === undefined || addrs.length === 0 ? (
                <div></div>
              ) : (
                addrs.map((addr) => (
                  <Grid item xs={12} md={6} key={addr.id}>
                    <Card sx={{ fontSize: "1.5rem" }}>
                      <CardHeader
                        sx={{
                          "& .MuiCardHeader-title": {
                            fontSize: "1.5rem",
                            fontWeight: 300,
                          },
                          "& .MuiCardHeader-subheader": {
                            fontSize: "1.5rem",
                            fontWeight: 300,
                          },
                          "& .MuiCardHeader-action": {
                            "& .MuiSvgIcon-root": {
                              fontSize: "20px",
                            },
                          },
                        }}
                        avatar={
                          <AvatarGroup max={4}>
                            <Avatar
                              alt="A"
                              sx={{
                                bgcolor:
                                  addr.default_addr === "Y" ? "orangered" : "",
                              }}
                            >
                              <LocalShippingIcon sx={{ fontSize: "25px" }} />
                            </Avatar>
                            <Avatar
                              alt="B"
                              sx={{
                                bgcolor:
                                  addr.default_bill_addr === "Y" ? "green" : "",
                              }}
                            >
                              <MonetizationOnIcon sx={{ fontSize: "25px" }} />
                            </Avatar>
                          </AvatarGroup>
                        }
                        title={
                          addr.default_addr === "Y" ? t("defaultShipping") : " "
                        }
                        subheader={
                          addr.default_bill_addr === "Y"
                            ? t("defaultBilling")
                            : " "
                        }
                        action={
                          <IconButton onClick={() => handleUpdateAddr(addr.id)}>
                            <BorderColorIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <div className="productName">
                          <b>{t("recipient")} :</b> {addr.receiver}
                        </div>
                        <div className="productName">
                          <b>{t("mobileno")} :</b> {addr.mobile}
                        </div>
                        <div className="productName">{addr.addr},</div>
                        <div className="productName">{addr.addr2},</div>
                        <div className="productName">
                          {addr.addr3 ? addr.addr3 : ""}
                        </div>
                        <div className="productName">
                          {addr.post_code} {addr.city}, {addr.state}
                        </div>
                      </CardContent>
                      <CardActions sx={{ backgroundColor: "#e8f4e0" }}>
                        <IconButton
                          color="error"
                          onClick={() => handleCfmDelete(addr.id)}
                        >
                          <DeleteForeverTwoToneIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                        {addr.default_addr === "N" && (
                          <Button
                            color="success"
                            sx={{ fontSize: "1.25rem" }}
                            onClick={() => handleShipping(addr.id)}
                          >
                            {t("setAsDefault")}
                          </Button>
                        )}
                        {addr.default_bill_addr === "N" && (
                          <Button
                            color="success"
                            sx={{ fontSize: "1.25rem" }}
                            onClick={() => handleBilling(addr.id)}
                          >
                            {t("setDefaultBilling")}
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddAddr
            handleCloseModal={handleCloseModal}
            setAddr={setAddr}
            setDefAddr={""}
            opt={modalopt}
          />
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openCfscr}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "16px",
          }}
        >
          {t("confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "16px",
            }}
          >
            {t("removeConfirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDlg}
            value="N"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("no")}
          </Button>
          <Button
            onClick={handleCloseDlg}
            value="Y"
            autoFocus
            variant="contained"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {t("recordDeleted")}
        </Alert>
      </Snackbar>
    </>
  );
}
