import http from "../http-user";

const view = () => {
  return http.get("/member/myprofil/view");
};

const update = (data) => {
  return http.post("/member/myprofil/updprofil", data);
};

const resetPassword = (data) => {
  return http.post(`/member/myprofil/updpasswd`, data);
};

const exportedObject = {
  view,
  update,
  resetPassword,
};

export default exportedObject;
