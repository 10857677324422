import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import FeaturedProductsProp from "../ProductsProp";
// import axios from "axios";
import "./AllProducts.css";
// import { getProducts } from "../../redux/apiCalls";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { Visibility } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import { PICTURE_SERVER } from "../../constant/constant";
import cookies from "js-cookie";
import CartService from "../../services/cart-service";

const AllProducts = (props) => {
  const { keyword } = props;
  const curLang = cookies.get("i18next") || "en";
  const { t } = useTranslation();
  let navigate = useNavigate();
  // const dispatch = useDispatch();

  // const products = useSelector((state)=>state.product.products);
  // const isFetching = useSelector((state)=>state.product.isFetching);

  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const handleClick = (id, opt) => {
    // dispatch(addProduct({...product,}))
    if (opt === "1") {
      navigate(`/product/${id}`);
    } else {
      navigate(`/shops/${id}`);
    }
    // navigate("/address");
  };

  // API call for featured collection on homepage - old code
  // useEffect(() => {
  //   (async () => {
  //      dispatch(await getProducts(keyword));
  //    })();
  //  }, [dispatch, keyword]);

  useEffect(() => {
    CartService.getSearchProd(keyword, curLang)
      .then((response) => {
        setProducts(response.data);
        setIsFetching(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [keyword, curLang]);

  return (
    <>
      <div className="all-products-container">
        <h1>
          {t("featured")}
          <span style={{ color: "##278441" }}> {t("collection")}</span>
        </h1>

        {isFetching ? (
          <div className="loader-search"></div>
        ) : products.length === 0 ? (
          <div className="product-empty">
            <div>
              <InfoTwoToneIcon
                style={{
                  fontSize: 30,
                  color: "orange",
                  marginRight: 5,
                }}
              />
            </div>
            <div style={{ minWidth: "150px" }}>{t("noDataAvailable")}</div>
          </div>
        ) : (
          <div className="all-products-wrapper">
            {products.map((product) => (
              <div className="image-container" key={product.id}>
                <div className="all-product-images">
                  <div style={{ backgroundColor: "#000" }}>
                    <img
                      src={`${PICTURE_SERVER}${product.pic}`}
                      alt=""
                      onClick={() => handleClick(product.id, "1")}
                    />
                    {/* <div className="all-cat-icons">
                    <Link to={`/product/${product._id}`}>
                      <Visibility className="all-icons" />
                    </Link>
                  </div> */}
                  </div>
                  <div className="all-Productdesc">
                    <div
                      className="shopinfo"
                      onClick={() => handleClick(product.shop, "2")}
                    >
                      <StorefrontTwoToneIcon
                        style={{
                          fontSize: 13,
                          color: "orange",
                        }}
                      />{" "}
                      {product.shop_name}
                    </div>
                    <h2>{product.sku_name}</h2>
                    {/* {products.rating} */}
                    <h2> RM {product.promo_price}</h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AllProducts;
