import http from "../http-user";

const viewbyid = (orderno) => {
  return http.get(`/member/delivery/view/${orderno}`);
};

const addDO = (data) => {
  return http.post(`/member/delivery/adddo`, data);
};

const update = (id, data) => {
  return http.put(`/member/delivery/upddo/${id}`, data);
};


const exportedObject = {
  viewbyid,
  addDO,
  update,
};

export default exportedObject;
