import React, { useState, useEffect } from "react";
import Footer from "../footer";
import "./membrefund.css";
import styled from "styled-components";
import { Add, Remove } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/apiCalls";

import undraw_empty_cart_co35 from "../../undraw_empty_cart_co35.png";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Modal,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
  Checkbox,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../../constant/constant";

// import UserCartService from "../../services/user-cart-service"; // for user
import UserOrderService from "../../services/user-order-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ApplyRefundOrder = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { t } = useTranslation();
  const { orderno } = useParams();
  const curLang = cookies.get("i18next") || "en";
  let navigate = useNavigate();
  const nbrfmt = new Intl.NumberFormat("en-GB", { minimumFractionDigits: 2 });

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [shopdlvr, setShopDlvr] = useState(0);
  const [custrtn, setCustRtn] = useState(0);
  const [rfnamt, setRfnamt] = useState(0);
  const [rtndata, setRtnData] = useState([]);
  const [subamt, setSubamt] = useState(0);
  const [shipamt, setShipamt] = useState(0);
  const [ordamt, setOrdamt] = useState(0);

  // const [selectedCart, setSelectedCart] = useState([]);
  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "success",
  });

  const { vertical, horizontal, open, message, severity } = msg;

  const [openBD, setOpenBD] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const handleQuantity = (type, idx) => {
    setOpenBD(!openBD);
    if (type === "dec") {
      if (rtndata[idx]["rfnqty"] < 0) {
        setOpenBD(false);
        setMsg({
          ...msg,
          open: true,
          message: `${t("min_qty_refund")}`,
          severity: "error",
        });
      } else {
        rtndata[idx]["balfrom"] = 0;
        rtndata[idx]["baldlvr"] = 0;
        rtndata[idx]["rfnqty"] = rtndata[idx]["rfnqty"] - 1;
        rtndata[idx]["rfnamt"] =
          rtndata[idx]["rfnqty"] * rtndata[idx]["unit_price"];
        // setRtnQty(rtndata[idx]["rfnqty"]);
        setOpenBD(false);
        // console.log("remove click", rtndata);
      }
    } else {
      if (rtndata[idx]["rfnqty"] + 1 > rtndata[idx]["ordered_qty"]) {
        setOpenBD(false);
        setMsg({
          ...msg,
          open: true,
          message: `${t("max_qty_refund")}  ${rtndata[idx]["ordered_qty"]}`,
          severity: "error",
        });
      } else {
        rtndata[idx]["balfrom"] = 0;
        rtndata[idx]["baldlvr"] = 0;
        rtndata[idx]["rfnqty"] = rtndata[idx]["rfnqty"] + 1;
        rtndata[idx]["rfnamt"] =
          rtndata[idx]["rfnqty"] * rtndata[idx]["unit_price"];
        // setRtnQty(rtndata[idx]["rfnqty"]);
        setOpenBD(false);
        // console.log("add click", rtndata);
      }
    }

    if (rtndata[idx]["rfnqty"] === rtndata[idx]["ordered_qty"]) {
      if (rtndata[idx]["delivered_qty"] > 0) {
        rtndata[idx]["balfrom"] = rtndata[idx]["delivered_qty"];
      }
    }

    if (rtndata[idx]["rfnqty"] < rtndata[idx]["ordered_qty"]) {
      rtndata[idx]["baldlvr"] =
        rtndata[idx]["ordered_qty"] -
        rtndata[idx]["delivered_qty"] -
        rtndata[idx]["rfnqty"];
    }

    if (!rtndata[idx]["selected"]) {
      setMsg({
        ...msg,
        open: true,
        message: `${t("select_item_to_refund")}`,
        severity: "warning",
      });
    }
    updateRefundInfo();
    // console.log("final", rtndata);
  };

  const handleContinueShop = () => {
    navigate("/");
  };

  const handleMyOrder = () => {
    navigate("/order/6");
  };

  const handleApplyRefund = () => {
    setOpenBD(!openBD);
    let j = 0;
    let cnt = 0;
    let amt = 0;
    let selectedbutnoinfo = "";
    // double check have refund item only continue
    for (j; j < rtndata.length; j++) {
      if (rtndata[j].selected) {
        cnt += 1;
        amt += rtndata[j].rfnamt;
        if (rtndata[j].rfnamt === 0) {
          selectedbutnoinfo += rtndata[j].name + ", ";
        }
      }
    }

    if (selectedbutnoinfo) {
      setMsg({
        ...msg,
        open: true,
        message: `${t(
          "no_item_selected_for_refund"
        )} for item ${selectedbutnoinfo}`,
        severity: "error",
      });
      setOpenBD(false);
      return false;
    } else {
      if (cnt > 0 && amt > 0) {
        let reason = document.getElementById("reason").value;
        let remark = document.getElementById("remark").value;
        let data = {
          reason,
          remark,
          itm: rtndata,
          ordno: orderno,
          shopdlvr,
          custrtn,
        };
        // console.log("apply data", data);
        UserOrderService.refundOrder(data)
          .then((response) => {
            setOpenBD(!openBD);
            navigate(`/refunddetail/${orderno}`);
          })
          .catch((e) => {
            if (e?.response?.status === 400) {
              logout(dispatch)
                .then(() => {
                  navigate("/login");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              console.log(e);
            }
          });
      } else {
        setMsg({
          ...msg,
          open: true,
          message: `${t("no_item_selected_for_refund")}`,
          severity: "error",
        });
      }
    }
  };

  const handleCheckBox = (e, idx) => {
    const { checked } = e.target;
    if (checked) {
      rtndata[idx]["selected"] = true;
      updateRefundInfo();
    } else {
      rtndata[idx]["selected"] = false;
      updateRefundInfo();
    }
  };

  const updateRefundInfo = () => {
    let rfnamt = 0;
    let ordamt = 0;
    let j = 0;
    let tshopdlvr = 0;
    let tcustrtn = 0;
    for (j; j < rtndata.length; j++) {
      if (rtndata[j].selected) {
        rfnamt += rtndata[j].rfnamt;
        if (rtndata[j].rfnamt > 0) {
          tshopdlvr += rtndata[j].baldlvr;
          tcustrtn += rtndata[j].balfrom;
        }
      }
      // else {
      //   ordamt += rtndata[j].ordered_qty * rtndata[j].unit_price;
      // }
      ordamt += rtndata[j].ordered_qty * rtndata[j].unit_price;
    }
    setRfnamt(rfnamt);
    // if (amt !== 0) {
    setShopDlvr(tshopdlvr);
    setCustRtn(tcustrtn);
    setSubamt(ordamt - rfnamt);
    setOrdamt(ordamt - rfnamt);
    // }
    // console.log("rfnamt", rfnamt);
    // console.log("ordamt", ordamt - rfnamt);
  };

  useEffect(() => {
    UserOrderService.viewByIdAndDlvr(orderno, curLang)
      .then((response) => {
        // console.log("refund", response.data);
        setProducts(response.data);
        setSubamt(response.data[0].prodamt);
        setShipamt(response.data[0].shipamt);
        setOrdamt(response.data[0].ordamt);
        let tmp = response.data[0].itemlist;
        let i = 0;
        let tmparr = [];
        for (i; i < tmp.length; i++) {
          let rtn = {
            id: tmp[i].id,
            name: tmp[i].sku_name,
            selected: false,
            ordered_qty: tmp[i].qty,
            delivered_qty: tmp[i].delivered_qty,
            pending: tmp[i].qty - tmp[i].delivered_qty,
            unit_price: tmp[i].unit_price,
            rfnamt: 0, // amount to refund
            rfnqty: 0, // qty to refund
            baldlvr: tmp[i].qty - tmp[i].delivered_qty, // balance qty to deliver customer
            balfrom: 0, // balance qty customer need to send back
            // maxrfnamt: tmp[i].total_amount, // only can refund amount for those not yet delivered
            // maxrfnqty: tmp[i].qty, // only can return qty that are delivered
            // rfntyp: "", // tmp[i].delivered_qty > 0 ? "2" : "1",
          };
          tmparr.push(rtn);
        }
        setRtnData(tmparr);
        // console.log("tmparr", tmparr);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  }, [curLang, orderno]);

  return (
    <>
      {products?.length === 0 ? (
        <div className="refund-empty">
          <img src={undraw_empty_cart_co35} alt="" />
        </div>
      ) : (
        <div className="refund-container">
          <div className="refund-wrapper">
            <h1 style={{ textTransform: "uppercase" }}>{t("requestrefund")}</h1>
            <div className="top">
              <div className="topText">
                <Topbutton type="filter" lng={curLang} onClick={handleMyOrder}>
                  {t("myOrders")}
                </Topbutton>
                <Topbutton
                  type="filter"
                  lng={curLang}
                  onClick={handleContinueShop}
                  style={{ marginLeft: "10px" }}
                >
                  {t("continueShopping")}
                </Topbutton>
              </div>
            </div>
            <div className="bottom">
              <div className="info">
                {products.map((shop) => (
                  <div className="productContainer" key={shop.id}>
                    <div className="productHeader">
                      <div className="headerinfo">
                        <StorefrontTwoToneIcon
                          style={{
                            fontSize: 15,
                            color: "orange",
                          }}
                        />{" "}
                        {shop.shop_name}
                      </div>
                    </div>
                    {shop.itemlist.map((product, index) => (
                      <div className="product" key={product.id}>
                        <div className="productDetail">
                          <div className="checkBox">
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              id={product.id.toString()}
                              value={product.id.toString()}
                              onChange={(e) => handleCheckBox(e, index)}
                            />
                          </div>
                          <div className="checkBox">
                            <img
                              src={`${PICTURE_SERVER}${product.pic}`}
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="productName">
                              <b>{t("product")} :</b> {product.sku_name}
                            </div>
                            <div className="productID">
                              <div>
                                <b>{t("unitPrice")} (RM) : </b>
                                {nbrfmt.format(product.unit_price)}
                              </div>
                              <div className="subitm">
                                <b>{t("total")} (RM) : </b>
                                {nbrfmt.format(product.total_amount)}
                              </div>
                            </div>
                            <div className="productID">
                              <div>
                                <b>{t("ordered_qty")} : </b>
                                {product.qty}
                              </div>
                              <div className="subitm">
                                <b>{t("deliveredqty")} : </b>
                                {product.delivered_qty}
                              </div>
                              <div className="subitm">
                                <b>{t("pending_qty")} : </b>
                                {Array.isArray(rtndata) && rtndata?.length
                                  ? rtndata[index]["pending"]
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="priceDetail">
                          <div className="productAmountContainer">
                            <div className="label">{t("qty_to_return")} :</div>
                            <IconButton
                              onClick={() => handleQuantity("dec", index)}
                              disabled={
                                Array.isArray(rtndata) && rtndata?.length
                                  ? rtndata[index]["rfnqty"] <= 0
                                    ? true
                                    : false
                                  : true
                              }
                            >
                              <Remove />
                            </IconButton>
                            <div className="productAmount">
                              {Array.isArray(rtndata) && rtndata?.length
                                ? rtndata[index]["rfnqty"]
                                : 0}
                            </div>
                            <IconButton
                              onClick={() => handleQuantity("inc", index)}
                              disabled={
                                Array.isArray(rtndata) && rtndata?.length
                                  ? rtndata[index]["rfnqty"] >=
                                    rtndata[index]["ordered_qty"]
                                    ? true
                                    : false
                                  : true
                              }
                            >
                              <Add />
                            </IconButton>
                          </div>
                          <div className="productAmountContainer">
                            <div className="label">
                              {t("refund_amt")} (RM) :{" "}
                              <span style={{ fontWeight: "500" }}>
                                {Array.isArray(rtndata) && rtndata?.length
                                  ? " " +
                                    nbrfmt.format(rtndata[index]["rfnamt"])
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="productAmountContainer">
                            <div className="label">
                              {t("delivered_qty_to_return")} :
                              <span style={{ fontWeight: "500" }}>
                                {Array.isArray(rtndata) && rtndata?.length
                                  ? " " + rtndata[index]["balfrom"]
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="productAmountContainer">
                            <div className="label">
                              {t("balance_qty_to_deliver")} :
                              <span style={{ fontWeight: "500" }}>
                                {Array.isArray(rtndata) && rtndata?.length
                                  ? " " + rtndata[index]["baldlvr"]
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="productContainer">
                  <div className="product">
                    <div className="productDetail">
                      <div className="detailremark" style={{ width: "100%" }}>
                        <div className="productName">{t("remark")}: </div>
                        <div>
                          <textarea
                            id="remark"
                            name="remark"
                            style={{
                              width: "100%",
                              height: "80px",
                              padding: "5px",
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="priceDetail">
                      <div className="productAmountContainer">
                        <div className="label">
                          Reason of refund :
                          <select
                            name="color"
                            id="reason"
                            style={{
                              padding: "5px",
                              fontSize: "15px",
                              width: "95%",
                            }}
                          >
                            <option value="1">{t("refundReason1")}</option>
                            <option value="2">{t("refundReason2")}</option>
                            <option value="3">{t("refundReason3")}</option>
                            <option value="4">{t("refundReason4")}</option>
                            <option value="5">{t("refundReason5")}</option>
                            <option value="6">{t("refundReason6")}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary">
                <div style={{ padding: "20px" }}>
                  <div className="summaryTitle">{t("refundSummary")}</div>
                  {/* <div className="summaryItem">
                  <div className="summaryItemText">{t("subtotal")} :</div>
                  <div className="summaryItemText">
                    RM {nbrfmt.format(subamt)}
                  </div>
                </div> */}
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      {t("shop_to_deliver")} :
                    </div>
                    <div className="summaryItemText">
                      <b>{shopdlvr}</b>
                    </div>
                  </div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      {t("customer_to_return")}:
                    </div>
                    <div className="summaryItemText">
                      <b>{custrtn}</b>
                    </div>
                  </div>
                  <div className="summaryItemBold">
                    <div className="summaryItemText">{t("total_refund")}</div>
                    <div className="summaryItemText">
                      RM {nbrfmt.format(rfnamt)}
                    </div>
                  </div>
                  {user ? (
                    <>
                      {rfnamt > 0 && (
                        <button
                          className="summaryButton"
                          onClick={handleApplyRefund}
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                            cursor: "pointer",
                            letterSpacing:
                              curLang === "cn" ? "1.5px" : "normal",
                          }}
                        >
                          {t("apply_refund")}
                        </button>
                      )}
                    </>
                  ) : (
                    <div>
                      <h2 style={{ color: "grey" }}>
                        PLEASE LOGIN TO CHECKOUT{" "}
                        <span
                          style={{ textDecoration: "underline", fontSize: 12 }}
                        >
                          <Link to="/login">Login</Link>
                        </span>
                        │
                        <span
                          style={{ textDecoration: "underline", fontSize: 12 }}
                        >
                          <Link to="/signup">SignUp</Link>
                        </span>{" "}
                      </h2>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#efefef",
                    padding: "20px",
                  }}
                >
                  <div
                    className="summaryTitle"
                    style={{
                      paddingTop: "5px",
                    }}
                  >
                    {t("orderSummary")} (PAID)
                  </div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      ({t("balance")}) {t("subtotal")} :
                    </div>
                    <div className="summaryItemText">
                      RM{" "}
                      {new Intl.NumberFormat("en-MY", {
                        minimumFractionDigits: 2,
                      }).format(subamt)}
                    </div>
                  </div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      ({t("balance")}) {t("shipping")} :
                    </div>
                    <div className="summaryItemText">
                      RM{" "}
                      {new Intl.NumberFormat("en-MY", {
                        minimumFractionDigits: 2,
                      }).format(shipamt)}
                    </div>
                  </div>
                  <div className="summaryItemBold">
                    <div className="summaryItemText">{t("total")}</div>
                    <div className="summaryItemText">
                      RM{" "}
                      {new Intl.NumberFormat("en-MY", {
                        minimumFractionDigits: 2,
                      }).format(ordamt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ApplyRefundOrder;
