import React, { useState, useEffect } from "react";
import "./membprofile.css";
import { logout } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  Snackbar,
  Typography,
  IconButton,
  Stack,
  Modal,
  Box,
} from "@mui/material";
import QrCode2TwoToneIcon from "@mui/icons-material/QrCode2TwoTone";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MuiAlert from "@mui/material/Alert";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SERVER_DOMAIN_REFERRAL } from "../../constant/constant";

import GenerateQrCode from "../component/gen_qrcode";
import UserService from "../../services/user-service";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MemberProfile = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const [utyp, setUserType] = useState("");
  const [userid, setUserid] = useState("");

  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const { vertical, horizontal, open, message } = msg;

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // bgcolor: "transparent",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const initialValues = {
    email: "",
    fullname: "",
    mobileno: "",
    username: user,
    refname: "",
  };

  const phone = [];
  phone["MY"] = /^[0][1][0-9]{8,11}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid") + ":" + t("email"))
      .required(t("isRequired") + " : " + t("email")),
    fullname: Yup.string().required(t("isRequired") + " : " + t("fullname")),
    mobileno: Yup.string()
      .required(t("isRequired") + " : " + t("mobileno"))
      .matches(phone["MY"], t("invalid") + ":" + t("phone")),
    username: Yup.string()
      .required(t("isRequired") + " : " + t("username"))
      .matches(
        /^[aA-zZ1-9]+$/,
        "No White Space And Special Character are Allowed"
      ),
  });

  // const {isFetching, loginfail} = useSelector((state)=> state.user)

  const copyText = () => {
    navigator.clipboard.writeText(SERVER_DOMAIN_REFERRAL + userid);
     setMsg({
       ...msg,
       open: true,
       message: `${t("link_copied")}`,
     });
  };

  const goToWhatsapp = () => {
    let invitUrl = SERVER_DOMAIN_REFERRAL + userid;
    let openWin = " https://api.whatsapp.com/send?text=" + encodeURIComponent(invitUrl);
    window.open(openWin, "_blank");
  }

  return (
    <>
      <div className="signup-container">
        <div className="signup-wrapper">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { props, setFieldError, setSubmitting }) => {
              setTimeout(() => {
                UserService.update(values)
                  .then((response) => {
                    setMsg({
                      ...msg,
                      open: true,
                      message: `${t("profilUpdSuccess")}`,
                    });
                    setSubmitting(false);
                  })
                  .catch((emsg) => {
                    if (emsg?.response?.status === 400) {
                      logout(dispatch)
                        .then(() => {
                          navigate("/login");
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    } else {
                      setFieldError("username", emsg);
                      setSubmitting(false);
                    }
                  });
              }, 200);
            }}
          >
            {function ShowForm(formik) {
              useEffect(() => {
                UserService.view()
                  .then((response) => {
                    const data = response.data;
                    setUserType(data.utype);
                    setUserid(data.user_id);
                    formik.setFieldValue("fullname", data.full_name, false);
                    if (data.email) {
                      formik.setFieldValue("email", data.email, false);
                    }
                    if (data.mobile_no) {
                      formik.setFieldValue("mobileno", data.mobile_no, false);
                    }
                    if (data.refname) {
                      formik.setFieldValue("refname", data.refname, false);
                    }
                  })
                  .catch((e) => {
                    if (e?.response?.status === 400) {
                      logout(dispatch)
                        .then(() => {
                          navigate("/login");
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    } else {
                      console.log(e);
                    }
                  });
              }, []);

              return (
                <Card sx={{ fontSize: "1.5rem", py: 3, px: 2 }}>
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid
                        container
                        mb={1.5}
                        sx={{ borderBottom: "1px solid #ececec" }}
                      >
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <h1>
                            {t("myProfile")}
                            {utyp === "A" && (
                              <span
                                style={{
                                  marginLeft: "1rem",
                                  color: "rgb(241, 27, 27)",
                                }}
                              >
                                ({t("sales_agent")})
                              </span>
                            )}
                          </h1>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        mb={3}
                      >
                        <Grid item xs={3}>
                          {t("referral_link")} :
                        </Grid>
                        <Grid item xs={9}>
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              className="iconbutton"
                              onClick={goToWhatsapp}
                            >
                              <WhatsAppIcon
                                sx={{
                                  fontSize: "2rem",
                                }}
                              />
                            </IconButton>
                            <IconButton
                              className="iconbutton"
                              onClick={copyText}
                            >
                              <ContentCopyTwoToneIcon
                                sx={{
                                  fontSize: "2rem",
                                }}
                              />
                            </IconButton>
                            <IconButton
                              className="iconbutton"
                              onClick={handleOpenModal}
                            >
                              <QrCode2TwoToneIcon sx={{ fontSize: "2rem" }} />
                            </IconButton>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          pb={1.5}
                          sx={{ borderBottom: "1px solid #ececec" }}
                        >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              fontSize: "1.25rem",
                            }}
                          >
                            {SERVER_DOMAIN_REFERRAL + userid}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={3} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            disabled
                            id="username"
                            name="username"
                            variant="outlined"
                            label={t("username")}
                            InputProps={{
                              inputProps: {
                                style: {
                                  fontSize: "1.5rem",
                                  padding: "1rem",
                                  backgroundColor: "#ececec",
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("username")}
                            error={
                              formik.touched.username &&
                              Boolean(formik.errors.username)
                            }
                          />
                          {formik.touched.username &&
                            formik.errors.username && (
                              <FormHelperText
                                id="username"
                                sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                              >
                                {formik.errors.username}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            placeholder={`${t("pleaseFillIn")}  :  ${t(
                              "email"
                            )}`}
                            id="email"
                            name="email"
                            variant="outlined"
                            label={t("email")}
                            InputProps={{
                              inputProps: {
                                style: { fontSize: "1.5rem", padding: "1rem" },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("email")}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                          />
                          {formik.touched.email && formik.errors.email && (
                            <FormHelperText
                              id="email"
                              sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                            >
                              {formik.errors.email}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            placeholder={`${t("pleaseFillIn")}  :  ${t(
                              "fullname"
                            )}`}
                            id="fullname"
                            name="fullname"
                            variant="outlined"
                            label={t("fullname")}
                            InputProps={{
                              inputProps: {
                                style: { fontSize: "1.5rem", padding: "1rem" },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("fullname")}
                            error={
                              formik.touched.fullname &&
                              Boolean(formik.errors.fullname)
                            }
                          />
                          {formik.touched.fullname &&
                            formik.errors.fullname && (
                              <FormHelperText
                                id="fullname"
                                sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                              >
                                {formik.errors.fullname}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            placeholder={`01xxxxxxxx`}
                            id="mobileno"
                            name="mobileno"
                            variant="outlined"
                            label={t("mobileno")}
                            InputProps={{
                              inputProps: {
                                style: { fontSize: "1.5rem", padding: "1rem" },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("mobileno")}
                            error={
                              formik.touched.mobileno &&
                              Boolean(formik.errors.mobileno)
                            }
                          />
                          {formik.touched.mobileno &&
                            formik.errors.mobileno && (
                              <FormHelperText
                                id="mobileno"
                                sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                              >
                                {formik.errors.mobileno}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            id="refname"
                            name="refname"
                            variant="outlined"
                            label={t("referral_name")}
                            InputProps={{
                              inputProps: {
                                style: {
                                  fontSize: "1.5rem",
                                  padding: "1rem",
                                  backgroundColor: "#ececec",
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("refname")}
                          />
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={3} spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="warning"
                            type="submit"
                            fullWidth
                            sx={{ p: 1, fontSize: "1.5rem" }}
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting && (
                              <CircularProgress size={28} color="success" />
                            )}
                            {!formik.isSubmitting && t("Update")}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              );
            }}
          </Formik>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <GenerateQrCode
            handleCloseModal={handleCloseModal}
            qrcode={SERVER_DOMAIN_REFERRAL + userid}
          />
        </Box>
      </Modal>
    </>
  );
};

export default MemberProfile;
