import * as React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "./sublogin.css";
import { login } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import LangService from "../../services/language-service";

export default function SubLogin({ handleCloseModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const {loginfail} = useSelector((state)=> state.user);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("isRequired") + " : " + t("username")),
    password: Yup.string().required(t("isRequired") + " : " + t("password")),
  });

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleForgotPassword = () => {};

  const handleCookie = () => {
    LangService.setCookies()
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCookie = () => {
    LangService.getCookies()
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="login-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { props, setFieldError, setSubmitting }) => {
          setTimeout(() => {
            login(dispatch, values)
              .then(() => {
                handleCloseModal();
              })
              .catch(() => {
                setFieldError("username", t("userNotExist"));
                setSubmitting(false);
              });
          }, 200);
        }}
      >
        {(formik) => (
          <Card sx={{ fontSize: "1.5rem", py: 3, px: 2 }}>
            <CardHeader
              sx={{
                "& .MuiCardHeader-title": {
                  fontSize: "20px",
                  fontWeight: 300,
                },
                "& .MuiCardHeader-action": {
                  "& .MuiSvgIcon-root": {
                    fontSize: "25px",
                  },
                },
              }}
              action={
                <IconButton onClick={() => handleCloseModal()}>
                  <HighlightOffIcon />
                </IconButton>
              }
              title={t("signIn")}
            />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      placeholder={`${t("pleaseFillIn")}  :  ${t("username")}`}
                      id="username"
                      name="username"
                      variant="outlined"
                      label={t("username")}
                      InputProps={{
                        inputProps: {
                          style: { fontSize: "1.5rem", padding: "1rem" },
                        },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                      }}
                      fullWidth
                      {...formik.getFieldProps("username")}
                      error={
                        formik.touched.username &&
                        Boolean(formik.errors.username)
                      }
                    />
                    {formik.touched.username && formik.errors.username && (
                      <FormHelperText
                        id="username"
                        sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                      >
                        {formik.errors.username}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder={`${t("pleaseFillIn")}  :  ${t("password")}`}
                      type="password"
                      id="password"
                      name="password"
                      variant="outlined"
                      label={t("password")}
                      InputProps={{
                        inputProps: {
                          style: { fontSize: "1.5rem", padding: "1rem" },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "1.35rem",
                          lineHeight: "1.25rem",
                          fontWeight: "500",
                        },
                      }}
                      fullWidth
                      {...formik.getFieldProps("password")}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                    />
                    {formik.touched.password && formik.errors.password && (
                      <FormHelperText
                        id="username"
                        sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                      >
                        {formik.errors.password}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="warning" //-- yck 26.03
                      type="submit"
                      fullWidth
                      sx={{ p: 1, fontSize: "1.5rem" }}
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting && (
                        <CircularProgress size={28} color="success" /> //-- yck 26.03
                      )}
                      {!formik.isSubmitting && t("login")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <CardActions>
              <Button
                sx={{ fontSize: "1.25rem", color: "#222222" }}
                onClick={() => handleSignUp()}
              >
                {t("signUp")}
              </Button>
              <Button
                sx={{ fontSize: "1.25rem", mr: 2, color: "#222222" }}
                onClick={() => handleForgotPassword()}
              >
                {t("forgotPassword")}
              </Button>
              {/* <Button
                sx={{ fontSize: "1.25rem", mr: 2, color: "#222222" }}
                onClick={() => handleCookie()}
              >
                set cookies
              </Button>
              <Button
                sx={{ fontSize: "1.25rem", mr: 2, color: "#222222" }}
                onClick={() => getCookie()}
              >
                get cookies
              </Button> */}
            </CardActions>
          </Card>
        )}
      </Formik>
    </div>
  );
}
