import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name:"user",
    initialState: {
        currentUser: null,
        token: null,
        isFetching:false,
        loginfail: false,
        typu: null,
    },
    reducers: {
       loginStart: (state)=> {
           state.isFetching = true;
           state.loginfail = false;
       },
       loginSuccess:(state, action)=> {
           state.isFetching = false;
           state.loginfail = false;
           state.currentUser = action.payload.user;
           state.token = action.payload.token;
           state.typu = action.payload.typu;
       },
       loginFailure: (state)=> {
           state.isFetching = false;
           state.loginfail = true;
           state.token = null;
           state.currentUser = null;
           state.typu = null;
       },
       logoutNow: (state)=> {
            state.currentUser = null;
            state.token = null;
            state.isFetching = false;
            state.loginfail = false;
            state.typu = null;
        },
        relogin: (state, action) => {
            state.token = action.payload.token;
        },
    }
});

export const { loginStart, loginSuccess, loginFailure, logoutNow, relogin } = userSlice.actions;
export default userSlice.reducer;