import React, { useState, useEffect, useCallback } from "react";
import Footer from "../footer";
import "./membrefund.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { logout } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

import undraw_empty_cart_co35 from "../../undraw_empty_cart_co35.png";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Snackbar,
  // Backdrop,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Modal,
  Box,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../../constant/constant";

import Delivery from "../component/delivery";
import UserOrderService from "../../services/user-order-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ApplyRefundOrder = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { t } = useTranslation();
  const { orderno } = useParams();
  const curLang = cookies.get("i18next") || "en";
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const nbrfmt = new Intl.NumberFormat("en-GB", { minimumFractionDigits: 2 });

  const [products, setProducts] = useState([]);
  const [shopdlvr, setShopDlvr] = useState(0);
  const [custrtn, setCustRtn] = useState(0);
  const [subamt, setSubamt] = useState(0);
  const [shipamt, setShipamt] = useState(0);
  const [ordamt, setOrdamt] = useState(0);

  const [opt, setOpt] = useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [openCfscr, setopenCfscr] = useState(false);
  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "success",
  });

  const { vertical, horizontal, open, message, severity } = msg;

  // const [openBD, setOpenBD] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const handleCloseDlg = (e) => {
    if (e.target.value === "Y") {
      setTimeout(() => {
        UserOrderService.withdrawRefund(orderno, curLang)
          .then((response) => {
            setopenCfscr(false);
            navigate(`/orderdeta/${orderno}`);
          })
          .catch((e) => {
            if (e?.response?.status === 400) {
              logout(dispatch)
                .then(() => {
                  navigate("/login");
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              console.log(e);
            }
          });
      }, 200);
    }
    setopenCfscr(false);
  };

  // const handleQuantity = (type, idx) => {
  //   setOpenBD(!openBD);
  //   if (type === "dec") {
  //     if (rtndata[idx]["rfnqty"] < 0) {
  //       setOpenBD(false);
  //       setMsg({
  //         ...msg,
  //         open: true,
  //         message: `${t("min_qty_refund")}`,
  //         severity: "error",
  //       });
  //     } else {
  //       rtndata[idx]["balfrom"] = 0;
  //       rtndata[idx]["baldlvr"] = 0;
  //       rtndata[idx]["rfnqty"] = rtndata[idx]["rfnqty"] - 1;
  //       rtndata[idx]["rfnamt"] =
  //         rtndata[idx]["rfnqty"] * rtndata[idx]["unit_price"];
  //       setRtnQty(rtndata[idx]["rfnqty"]);
  //       setOpenBD(false);
  //       console.log("remove click", rtndata);
  //     }
  //   } else {
  //     if (rtndata[idx]["rfnqty"] + 1 > rtndata[idx]["ordered_qty"]) {
  //       setOpenBD(false);
  //       setMsg({
  //         ...msg,
  //         open: true,
  //         message: `${t("max_qty_refund")}  ${rtndata[idx]["ordered_qty"]}`,
  //         severity: "error",
  //       });
  //     } else {
  //       rtndata[idx]["balfrom"] = 0;
  //       rtndata[idx]["baldlvr"] = 0;
  //       rtndata[idx]["rfnqty"] = rtndata[idx]["rfnqty"] + 1;
  //       rtndata[idx]["rfnamt"] =
  //         rtndata[idx]["rfnqty"] * rtndata[idx]["unit_price"];
  //       setRtnQty(rtndata[idx]["rfnqty"]);
  //       setOpenBD(false);
  //       console.log("add click", rtndata);
  //     }
  //   }

  //   if (rtndata[idx]["rfnqty"] === rtndata[idx]["ordered_qty"]) {
  //     if (rtndata[idx]["delivered_qty"] > 0) {
  //       rtndata[idx]["balfrom"] = rtndata[idx]["delivered_qty"];
  //     }
  //   }

  //   if (rtndata[idx]["rfnqty"] < rtndata[idx]["ordered_qty"]) {
  //     rtndata[idx]["baldlvr"] =
  //       rtndata[idx]["ordered_qty"] -
  //       rtndata[idx]["delivered_qty"] -
  //       rtndata[idx]["rfnqty"];
  //   }

  //   if (!rtndata[idx]["selected"]) {
  //     setMsg({
  //       ...msg,
  //       open: true,
  //       message: `${t("select_item_to_refund")}`,
  //       severity: "warning",
  //     });
  //   }
  //   updateRefundInfo();
  //   console.log("final", rtndata);
  // };

  const handleContinueShop = () => {
    navigate("/");
  };

  const handleMyOrder = () => {
    navigate("/order/6");
  };

  const handleDelivery = (popt) => {
    setOpt(popt);
    handleOpenModal();
  };

  const handleCancelRefund = () => {
    setopenCfscr(true);
  };

  const refreshData = useCallback(() => {
    UserOrderService.viewRefundById(orderno, curLang)
      .then((response) => {
        // console.log("refund", response.data);
        setProducts(response.data);
        // console.log(response.data[0].refund);

        // to get active items amount
        let tmp = response.data[0].itemlist;
        if (tmp.length > 0) {
          let i = 0;
          let prodamt = 0;
          for (i; i < tmp.length; i++) {
            prodamt += +tmp[i].total_amount - +tmp[i].refund_amt;
          }

          setShopDlvr(response.data[0].refund.shop_deliver);
          setCustRtn(response.data[0].refund.customer_return);
          setSubamt(prodamt);
          setOrdamt(prodamt);
          // console.log("tmp", tmp);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  }, [orderno, curLang]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <>
      {products?.length === 0 ? (
        <div className="refund-empty">
          <img src={undraw_empty_cart_co35} alt="" />
        </div>
      ) : (
        <div className="refund-container">
          <div className="refund-wrapper">
            <h1 style={{ textTransform: "uppercase" }}>{t("requestrefund")}</h1>
            <div className="top">
              <div className="topText">
                <Topbutton type="filter" lng={curLang} onClick={handleMyOrder}>
                  {t("myOrders")}
                </Topbutton>
                <Topbutton
                  type="filter"
                  lng={curLang}
                  onClick={handleContinueShop}
                  style={{ marginLeft: "10px" }}
                >
                  {t("continueShopping")}
                </Topbutton>
              </div>
              <div className="topText">
                <h1>
                  {products?.length &&
                    products[0].refund.return_money_sts === 0 &&
                    t("refund_closed")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 1 &&
                    t("customer_apply")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 2 &&
                    t("merchant_acceptance")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 3 &&
                    t("customer_delivery")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 4 &&
                    t("merchant_received")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 5 &&
                    t("refund_successful")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 6 &&
                    t("customer_withdraw")}
                  {products?.length &&
                    products[0].refund.return_money_sts === 7 &&
                    t("merchant_reject")}
                </h1>
              </div>
            </div>
            <div className="bottom">
              <div className="info">
                <div className="productContainer">
                  <div className="product">
                    <div className="return">
                      <div className="content">
                        <div>{t("application_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length && products[0].refund.apply_time}
                        </div>
                      </div>
                      <div className="content">
                        <div>{t("acceptance_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length &&
                            products[0].refund.processing_time}
                        </div>
                      </div>
                      <div className="content">
                        <div>{t("customer_delivery_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length && products[0].refund.ship_time}
                          {products?.length &&
                            custrtn > 0 &&
                            !products[0].refund.ship_time && (
                              <Topbutton
                                type="filter"
                                lng={curLang}
                                onClick={() => handleDelivery(0)}
                              >
                                {t("update_delivery_info")}
                              </Topbutton>
                            )}
                        </div>
                      </div>
                      {products?.length &&
                        custrtn > 0 &&
                        products[0].refund.ship_time && (
                          <div
                            className="content"
                            style={{ cursor: "pointer", color: "gray" }}
                            onClick={() => handleDelivery(1)}
                          >
                            [{products[0].refund.dvy_comp} (
                            {products[0].refund.dvy_cons_note} )]
                          </div>
                        )}
                      <div className="content">
                        <div>{t("merchant_receive_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length && products[0].refund.receive_time}
                        </div>
                      </div>
                      <div className="content">
                        <div>{t("refund_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length && products[0].refund.refund_time}
                        </div>
                      </div>
                    </div>
                    <div
                      className="return"
                      style={{ backgroundColor: "rgb(250, 214, 224)" }}
                    >
                      <div className="content">
                        <div>Status : </div>
                        <div style={{ marginLeft: "5px", color: "red" }}>
                          {products?.length &&
                            products[0].refund.return_money_sts === 0 &&
                            t("refund_closed")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 1 &&
                            t("customer_apply")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 2 &&
                            t("merchant_acceptance")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 3 &&
                            t("customer_delivery")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 4 &&
                            t("merchant_received")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 5 &&
                            t("refund_successful")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 6 &&
                            t("customer_withdraw")}
                          {products?.length &&
                            products[0].refund.return_money_sts === 7 &&
                            t("merchant_reject")}
                        </div>
                      </div>
                      <div className="content">
                        <div>{t("withdrawal_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length && products[0].refund.cancel_time}
                        </div>
                      </div>
                      <div className="content">
                        <div>{t("reject_time")} :</div>
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length && products[0].refund.reject_time}
                        </div>
                      </div>
                      <div className="content">
                        <div style={{ marginLeft: "5px" }}>
                          {products?.length &&
                            products[0].refund.reject_message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {products.map((shop) => (
                  <div className="productContainer" key={shop.id}>
                    <div className="productHeader">
                      <div className="headerinfo">
                        <StorefrontTwoToneIcon
                          style={{
                            fontSize: 15,
                            color: "orange",
                          }}
                        />{" "}
                        {shop.shop_name}
                      </div>
                    </div>
                    {shop.itemlist.map((product, index) => (
                      <div className="product" key={product.id}>
                        <div className="productDetail">
                          <div className="checkBox2">
                            <img
                              src={`${PICTURE_SERVER}${product.pic}`}
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="productName">
                              <b>{t("product")} :</b> {product.sku_name}
                            </div>
                            <div className="productID">
                              <div>
                                <b>{t("unitPrice")} (RM) : </b>
                                {nbrfmt.format(product.unit_price)}
                              </div>
                              <div className="subitm">
                                <b>{t("total")} (RM) : </b>
                                {nbrfmt.format(product.total_amount)}
                              </div>
                            </div>
                            <div className="productID">
                              <div>
                                <b>{t("ordered_qty")} : </b>
                                {product.qty}
                              </div>
                              <div className="subitm">
                                <b>{t("deliveredqty")} : </b>
                                {product.delivered_qty}
                              </div>
                              <div className="subitm">
                                <b>{t("pending_qty")} : </b>
                                {product.qty - product.delivered_qty}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="priceDetail"
                          style={
                            product.refund_qty === 0
                              ? { backgroundColor: "rgb(176, 246, 176)" }
                              : {}
                          }
                        >
                          <div className="productAmountContainer">
                            <div className="label">{t("qty_to_return")} :</div>
                            <div className="productAmount">
                              {product.refund_qty}
                            </div>
                          </div>
                          <div className="productAmountContainer">
                            <div className="label">
                              {t("refund_amt")} (RM) :{" "}
                              <span style={{ fontWeight: "500" }}>
                                {nbrfmt.format(+product.refund_amt)}
                              </span>
                            </div>
                          </div>
                          <div className="productAmountContainer">
                            <div className="label">
                              {t("delivered_qty_to_return")} :
                              <span style={{ fontWeight: "500" }}>
                                {" " + product.cust_return}
                              </span>
                            </div>
                          </div>
                          <div className="productAmountContainer">
                            <div className="label">
                              {t("balance_qty_to_deliver")} :
                              <span style={{ fontWeight: "500" }}>
                                {" " + product.shop_deliver}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="productContainer">
                  <div className="product">
                    <div className="productDetail">
                      <div className="detailremark" style={{ width: "100%" }}>
                        <div className="productName">{t("remark")}: </div>
                        <div className="productName">
                          {products?.length
                            ? products[0].refund.buyer_desc
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="priceDetail">
                      <div className="productAmountContainer">
                        <div className="label">
                          {t("refund_reason")} :
                          <select
                            name="color"
                            id="reason"
                            style={{
                              padding: "5px",
                              fontSize: "15px",
                              width: "95%",
                            }}
                          >
                            {products?.length &&
                              products[0].refund.buyer_reason === "1" && (
                                <option value="1">{t("refundReason1")}</option>
                              )}
                            {products?.length &&
                              products[0].refund.buyer_reason === "2" && (
                                <option value="2">{t("refundReason2")}</option>
                              )}
                            {products?.length &&
                              products[0].refund.buyer_reason === "3" && (
                                <option value="3">{t("refundReason3")}</option>
                              )}
                            {products?.length &&
                              products[0].refund.buyer_reason === "4" && (
                                <option value="4">{t("refundReason4")}</option>
                              )}
                            {products?.length &&
                              products[0].refund.buyer_reason === "5" && (
                                <option value="5">{t("refundReason5")}</option>
                              )}
                            {products?.length &&
                              products[0].refund.buyer_reason === "6" && (
                                <option value="6">{t("refundReason6")}</option>
                              )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary">
                <div style={{ padding: "20px" }}>
                  <div className="summaryTitle">{t("refundSummary")}</div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      {t("shop_to_deliver")} :
                    </div>
                    <div className="summaryItemText">
                      <b>{shopdlvr}</b>
                    </div>
                  </div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      {t("customer_to_return")}:
                    </div>
                    <div className="summaryItemText">
                      <b>{custrtn}</b>
                    </div>
                  </div>
                  <div className="summaryItemBold">
                    <div className="summaryItemText">{t("total_refund")}</div>
                    <div className="summaryItemText">
                      RM{" "}
                      {nbrfmt.format(
                        products?.length && products[0].refund.refund_amount
                      )}
                    </div>
                  </div>
                  {user ? (
                    products[0].refund.return_money_sts === 1 && (
                      <button
                        className="summaryButton"
                        onClick={handleCancelRefund}
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                          letterSpacing: curLang === "cn" ? "1.5px" : "normal",
                        }}
                      >
                        {t("cancel_refund")}
                      </button>
                    )
                  ) : (
                    <div>
                      <h2 style={{ color: "grey" }}>
                        PLEASE LOGIN TO CHECKOUT{" "}
                        <span
                          style={{ textDecoration: "underline", fontSize: 12 }}
                        >
                          <Link to="/login">Login</Link>
                        </span>
                        │
                        <span
                          style={{ textDecoration: "underline", fontSize: 12 }}
                        >
                          <Link to="/signup">SignUp</Link>
                        </span>{" "}
                      </h2>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#e8f4e0",
                    padding: "20px",
                  }}
                >
                  <div
                    className="summaryTitle"
                    style={{
                      paddingTop: "5px",
                    }}
                  >
                    {t("orderSummary")} (PAID)
                  </div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      ({t("balance")}) {t("subtotal")} :
                    </div>
                    <div className="summaryItemText">
                      RM{" "}
                      {new Intl.NumberFormat("en-MY", {
                        minimumFractionDigits: 2,
                      }).format(subamt)}
                    </div>
                  </div>
                  <div className="summaryItem">
                    <div className="summaryItemText">
                      ({t("balance")}) {t("shipping")} :
                    </div>
                    <div className="summaryItemText">
                      RM{" "}
                      {new Intl.NumberFormat("en-MY", {
                        minimumFractionDigits: 2,
                      }).format(shipamt)}
                    </div>
                  </div>
                  <div className="summaryItemBold">
                    <div className="summaryItemText">{t("total")}</div>
                    <div className="summaryItemText">
                      RM{" "}
                      {new Intl.NumberFormat("en-MY", {
                        minimumFractionDigits: 2,
                      }).format(ordamt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Dialog
        open={openCfscr}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "16px",
          }}
        >
          {t("confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "16px",
            }}
          >
            {t("cancel_withdrawal")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDlg}
            value="N"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("no")}
          </Button>
          <Button
            onClick={handleCloseDlg}
            value="Y"
            autoFocus
            variant="contained"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="checkout-modal">
          <Delivery
            handleCloseModal={handleCloseModal}
            orderno={orderno}
            // curLang={curLang}
            opt={opt} // 0 = add, 1 = update
            refreshData={refreshData}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ApplyRefundOrder;
