import React, { useState, useEffect, Fragment } from "react";
import Footer from "../footer";
import "./memborder.css";
import styled from "styled-components";
import { logout } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import {
  Tabs,
  Tab,
  Typography,
  Pagination,
  Stack,
  Box,
  Snackbar,
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../../constant/constant";

import UserOrderService from "../../services/user-order-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MyOrder = () => {
  // const user = useSelector((state) => state.user.currentUser);
  const { tab } = useParams();
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = useState(+tab);

  const handleChangeTab = (event, newValue) => {
    setPage(1);
    setValue(newValue);
  };

  const [page, setPage] = useState(1);
  const handleChangePage = (event, pgval) => {
    setPage(pgval);
  };

  // const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  // const [prodcnt, setProdCnt] = useState(0);
  // const [subamt, setSubamt] = useState(0);
  const [pagecnt, setPageCnt] = useState(1);
  const [openCfscr, setopenCfscr] = useState(false);
  const [actionopt, setActionOpt] = useState("");
  const [actionid, setActionId] = useState("");
  const [ordstat, setOrdStat] = useState({});

  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "success",
  });

  const { vertical, horizontal, open, message, severity } = msg;

  const [openBD, setOpenBD] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const handleCloseDlg = (e) => {
    if (e.target.value === "Y") {
      setTimeout(() => {
        if (actionopt === "1") {
          // for product cancel
          UserOrderService.cancelItem(actionid)
            .then((response) => {
              refreshData();
              setopenCfscr(false);
              // setMsg({ ...msg, open: true });
            })
            .catch((e) => {
              if (e?.response?.status === 400) {
                logout(dispatch)
                  .then(() => {
                    navigate("/login");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                console.log(e);
              }
            });
        } else if (actionopt === "2") {
          // for bill / order cancel
          UserOrderService.cancelOrder(actionid)
            .then((response) => {
              refreshData();
              setopenCfscr(false);
              // setMsg({ ...msg, open: true });
            })
            .catch((e) => {
              if (e?.response?.status === 400) {
                logout(dispatch)
                  .then(() => {
                    navigate("/login");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                console.log(e);
              }
            });
        } else if (actionopt === "3") {
          // for order confirmation
          UserOrderService.confirmOrder(actionid)
            .then((response) => {
              refreshData();
              setopenCfscr(false);
              setMsg({
                ...msg,
                message: t("order_confirm_received"),
                open: true,
              });
            })
            .catch((e) => {
              if (e?.response?.status === 400) {
                logout(dispatch)
                  .then(() => {
                    navigate("/login");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                console.log(e);
              }
            });
        } else if (actionopt === "4") {
          setopenCfscr(false);
          navigate(`/applyrefund/${actionid}`);
          // for order return refund
          // UserOrderService.refundOrder(actionid)
          //   .then((response) => {
          // refreshData();
          //     setopenCfscr(false);
          //     setMsg({ ...msg, open: true });
          //   })
          // .catch((e) => {
          //     if (e?.response?.status === 400) {
          //       logout(dispatch)
          //         .then(() => {
          //           navigate("/login");
          //         })
          //         .catch((e) => {
          //           console.log(e);
          //         });
          //     } else {
          //       console.log(e);
          //     }
          //   });
        } else if (actionopt === "5") {
          // for product return refund
          // UserOrderService.refundItem(actionid)
          //   .then((response) => {
          // refreshData();
          //     setopenCfscr(false);
          //     setMsg({ ...msg, open: true });
          //   })
          // .catch((e) => {
          //     if (e?.response?.status === 400) {
          //       logout(dispatch)
          //         .then(() => {
          //           navigate("/login");
          //         })
          //         .catch((e) => {
          //           console.log(e);
          //         });
          //     } else {
          //       console.log(e);
          //     }
          //   });
        }
      }, 200);
    }
    setopenCfscr(false);
  };

  // const handleItemCancel = (itemid) => {
  //   setActionOpt("1");
  //   setActionId(itemid);
  //   setopenCfscr(true);
  // };

  // const handleItemRefund = (itemid) => {
  //   setActionOpt("5");
  //   setActionId(itemid);
  //   setopenCfscr(true);
  // };

  const handleOrderCancel = (orderno) => {
    setActionOpt("2");
    setActionId(orderno);
    setopenCfscr(true);
  };

  const handleOrderReceived = (orderno) => {
    setActionOpt("3");
    setActionId(orderno);
    setopenCfscr(true);
  };

  const handleOrderRefund = (orderno) => {
    setActionOpt("4");
    setActionId(orderno);
    setopenCfscr(true);
  };

  const handleOrderDetail = (orderno, stat) => {
    if (stat === 7 || stat === 6) {
      navigate(`/refunddetail/${orderno}`);
    } else {
      navigate(`/orderdeta/${orderno}`);
    }
  };

  const handlePayNow = (orderno, addrid) => {
    navigate(`/checkoutpymt/${orderno}/${addrid}/2`);
  };

  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      mins = "" + d.getMinutes(),
      sec = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (mins.length < 2) mins = "0" + mins;
    if (sec.length < 2) sec = "0" + sec;

    return [day, month, year].join("/") + " " + hour + ":" + mins + ":" + sec;
  }

  const refreshData = () => {
    UserOrderService.view(curLang, page, value)
      .then((response) => {
        // console.log("res", response.data);
        setOrders(response.data.orders);
        setPageCnt(response.data.pagecnt);
        setOrdStat(response.data.stat);
        setOpenBD(false);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  };

  useEffect(() => {
    setOpenBD(!openBD);
    refreshData();
  }, [curLang, page, value]);

  return (
    <>
      <div className="order-container">
        <div className="order-wrapper">
          <h1>{t("myOrders")}</h1>
          <Box
            sx={{
              maxWidth: { xs: 320, sm: "100%" },
              bgcolor: "background.paper",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              sx={{
                "& .MuiTabScrollButton-root": {
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.7rem",
                  },
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#278441",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#278441",
                },
              }}
            >
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("all")}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("topay")}{" "}
                    {ordstat?.topay > 0 ? "(" + ordstat?.topay + ")" : ""}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("toship")}{" "}
                    {ordstat?.toship > 0 ? "(" + ordstat?.toship + ")" : ""}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("toreceive")}{" "}
                    {ordstat?.toreceive > 0
                      ? "(" + ordstat?.toreceive + ")"
                      : ""}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("completed")}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("cancelled")}
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{ fontSize: "1.6rem", textTransform: "capitalize" }}
                  >
                    {t("refund")}{" "}
                    {ordstat?.refund > 0 ? "(" + ordstat?.refund + ")" : ""}
                  </Typography>
                }
              />
            </Tabs>
          </Box>
          {orders.length === 0 ? (
            <div className="order-empty">
              <div>
                <InfoTwoToneIcon
                  style={{
                    fontSize: 30,
                    color: "orange",
                    marginRight: 5,
                  }}
                />
              </div>
              <div style={{ minWidth: "150px" }}>{t("noDataAvailable")}</div>
            </div>
          ) : (
            <>
              <div className="bottom">
                <div className="info">
                  {orders.map((order) => (
                    <div className="productContainer" key={order.order_number}>
                      <div className="productHeader">
                        <div className="headerinfo">
                          <div className="shopContainer">
                            <div className="shopinfo">
                              <StorefrontTwoToneIcon
                                style={{
                                  fontSize: 18,
                                  color: "orange",
                                }}
                              />
                              {" " + order.shop_name}
                            </div>
                            <div className="orderinfo">
                              <span className="divider">{" | "}</span>
                              {t("orderdate") + " : "}
                            </div>
                            <div className="orderinfo">
                              {formatDate(order.created_at)}
                            </div>
                            <div className="orderinfo">
                              {order.invoice_num && (
                                <>
                                  <span className="divider">{" | "}</span>
                                  {t("invoiceno") + " : " + order.invoice_num}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="headerinfo">
                          <div className="statusContainer">
                            <div className="statusinfo">
                              <LocalShippingTwoToneIcon />
                            </div>
                            {order.delivery.map((delivery) => (
                              <Fragment key={delivery.id}>
                                <div className="statusinfo">
                                  ({delivery.dvy_comp})
                                </div>
                                <div className="statusinfo">
                                  {delivery.dvy_cons_note}
                                  <span className="divider">{" | "}</span>
                                </div>
                              </Fragment>
                            ))}
                            {order.return_money_sts && (
                              <div className="statusinfo">
                                <span className="orderstatus">
                                  {order.return_money_sts === 0 &&
                                    "(" + t("refund_closed") + ")"}
                                  {order.return_money_sts === 1 &&
                                    "(" + t("customer_apply") + ")"}
                                  {order.return_money_sts === 2 &&
                                    "(" + t("merchant_acceptance") + ")"}
                                  {order.return_money_sts === 3 &&
                                    "(" + t("customer_delivery") + ")"}
                                  {order.return_money_sts === 4 &&
                                    "(" + t("merchant_received") + ")"}
                                  {order.return_money_sts === 5 &&
                                    "(" + t("refund_successful") + ")"}
                                  {order.return_money_sts === 6 &&
                                    "(" + t("customer_withdraw")}
                                  {order.return_money_sts === 7 &&
                                    "(" + t("merchant_reject") + ")"}
                                </span>
                              </div>
                            )}
                            <div className="statusinfo">
                              <span className="orderstatus">
                                {order.ostatus === 1 && t("topay")}
                                {order.ostatus === 2 && t("toship")}
                                {order.ostatus === 3 && t("toreceive")}
                                {order.ostatus === 4 && t("completed")}
                                {order.ostatus === 0 && t("cancelled")}
                                {order.ostatus === 6 && t("partialrefund")}
                                {order.ostatus === 7 && t("refund")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {order.order_item.map((product) => (
                        <div className="product" key={product.id}>
                          <div
                            className="productDetail"
                            onClick={() =>
                              handleOrderDetail(
                                order.order_number,
                                order.ostatus
                              )
                            }
                          >
                            <img
                              src={`${PICTURE_SERVER}${product.pic}`}
                              alt=""
                            />
                            <div className="details">
                              <div className="productName">
                                <b>{t("product")} :</b> {product.sku_name}
                              </div>
                              <div className="productID">
                                <b>{t("unitPrice")} :</b> RM{" "}
                                {new Intl.NumberFormat("en-MY", {
                                  minimumFractionDigits: 2,
                                }).format(product.unit_price)}
                              </div>
                              {product.uom1 && product.uom1 !== "std" && (
                                <div className="productSize">
                                  <b>{t(product.uom1)} : </b>
                                  {product.uom1desc}
                                </div>
                              )}
                              {product.uom2 && product.uom2 !== "std" && (
                                <div className="productSize">
                                  <b>{t(product.uom2)} : </b>
                                  {product.uom2desc}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="priceDetail">
                            <div className="productPrice">
                              (x {product.qty}) RM{" "}
                              {new Intl.NumberFormat("en-MY", {
                                minimumFractionDigits: 2,
                              }).format(product.total_amount)}
                            </div>
                            {/* <div>
                              {product.istatus === -1 && (
                                <Button
                                  sx={{ fontSize: "12px" }}
                                  color="error"
                                  onClick={() => handleItemCancel(product.id)}
                                >
                                  {t("cancelthisprod")}
                                </Button>
                              )}
                              {(product.istatus === 2 ||
                                product.istatus === 3) && (
                                <Button
                                  sx={{ fontSize: "12px" }}
                                  color="error"
                                  onClick={() => handleItemRefund(product.id)}
                                >
                                  {t("refundthisprod")}
                                </Button>
                              )}
                              {product.istatus === 0 && (
                                <Button sx={{ fontSize: "12px" }} color="error">
                                  {t("cancelled")}
                                </Button>
                              )}
                              {product.istatus === 6 && (
                                <Button
                                  sx={{ fontSize: "12px" }}
                                  color="primary"
                                >
                                  {t("requestedrefund")}
                                </Button>
                              )}
                              {product.istatus === 7 && (
                                <Button
                                  sx={{ fontSize: "12px" }}
                                  color="primary"
                                >
                                  {t("acceptedrefund")}
                                </Button>
                              )}
                              {product.istatus === 8 && (
                                <Button
                                  sx={{ fontSize: "12px" }}
                                  color="primary"
                                >
                                  {t("pendingrefund")}
                                </Button>
                              )}
                              {product.istatus === 9 && (
                                <Button
                                  sx={{ fontSize: "12px" }}
                                  color="success"
                                >
                                  {t("donerefund")}
                                </Button>
                              )}
                              {product.istatus === 10 && (
                                <Button sx={{ fontSize: "12px" }} color="error">
                                  {t("rejectedrefund")}
                                </Button>
                              )}
                            </div> */}
                          </div>
                        </div>
                      ))}
                      <div className="productFooter">
                        {order.ostatus === 1 && (
                          <div className="footerinfo">
                            <Topbutton
                              type="filter"
                              lng={curLang}
                              onClick={() =>
                                handlePayNow(
                                  order.order_number,
                                  order.order_address.addr_id
                                )
                              }
                            >
                              {t("payNow")}
                            </Topbutton>
                          </div>
                        )}
                        {order.ostatus === 3 && (
                          <div className="footerinfo">
                            <Topbutton
                              type="filter"
                              lng={curLang}
                              onClick={() =>
                                handleOrderReceived(order.order_number)
                              }
                            >
                              {t("orderreceived")}
                            </Topbutton>
                          </div>
                        )}
                        {(order.ostatus === 2 ||
                          order.ostatus === 3 ||
                          order.ostatus === 6) && (
                          <div className="footerinfo">
                            <Button
                              sx={{ fontSize: "14px" }}
                              color="error"
                              onClick={() =>
                                handleOrderRefund(order.order_number)
                              }
                            >
                              {t("requestrefund")}
                            </Button>
                          </div>
                        )}
                        {order.ostatus === 1 && (
                          <div className="footerinfo">
                            <Button
                              sx={{ fontSize: "14px" }}
                              color="error"
                              onClick={() =>
                                handleOrderCancel(order.order_number)
                              }
                            >
                              {t("cancelwholeorder")}
                            </Button>
                          </div>
                        )}
                        <div className="footerinfo">
                          {t("ordertotal") + " : "}
                          <span className="total">RM {order.total}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={pagecnt}
                    page={page}
                    size="small"
                    onChange={handleChangePage}
                    siblingCount={1}
                    boundaryCount={1}
                    color="success"
                    sx={{
                      "& .MuiPaginationItem-icon": {
                        fontSize: "1.7rem",
                      },
                      "& .MuiPaginationItem-root": {
                        fontSize: { xs: "1.4rem", sm: "1.5rem" },
                      },
                      "& .MuiPaginationItem-sizeSmall": {
                        margin: { xs: 0.5, sm: 1 },
                        lineHeight: { xs: "1.6rem", sm: "1.7rem" },
                      },
                      // "& .MuiPaginationItem-root.Mui-selected": {
                      //   color: "white",
                      //   backgroundColor: "orange",
                      // },
                    }}
                  />
                </Stack>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openCfscr}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "16px",
          }}
        >
          {t("confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "16px",
            }}
          >
            {actionopt === "1" && t("itemCancelConfirmation")}
            {actionopt === "2" && t("orderCancelConfirmation")}
            {actionopt === "3" && t("orderReceiveConfirmation")}
            {actionopt === "4" && t("orderRefundConfirmation")}
            {actionopt === "5" && t("itemRefundConfirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDlg}
            value="N"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("no")}
          </Button>
          <Button
            onClick={handleCloseDlg}
            value="Y"
            autoFocus
            variant="contained"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyOrder;
