import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Button } from "@mui/material";
import "./checkoutpymtstat.css";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UserOrderService from "../../services/user-order-service"; // for user

export default function VariableWidthGrid() {
  const { orderno } = useParams();
  const { t } = useTranslation();

  const [pymtstat, setPymtStat] = useState("");
  let navigate = useNavigate();

  const handleContinueShop = () => {
    navigate("/");
  };

  const handleMyOrders = () => {
    navigate("/order/0");
  };

  useEffect(() => {
    UserOrderService.getOrderPymt(orderno)
      .then((response) => {
        setPymtStat(response.data.status);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [orderno]);

  return (
    <div
      className="pymtstat-container"
      style={
        pymtstat === "1"
          ? { backgroundColor: "rgb(40, 224, 40)" }
          : pymtstat === "0"
          ? { backgroundColor: "#ffe5e5" }
          : { backgroundColor: "#fff" }
      }
    >
      <div className="pymtstat-wrapper">
        <Card sx={{ fontSize: "1.5rem", py: 3, px: 2 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <h1>{t("paymentStatus")}</h1>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {pymtstat === "1" && (
                  <CheckCircleTwoToneIcon
                    style={{
                      fontSize: 60,
                      color: "rgb(8, 172, 8)",
                    }}
                  />
                )}
                {pymtstat === "0" && (
                  <CancelTwoToneIcon
                    style={{
                      fontSize: 60,
                      color: "rgb(255, 4, 0)",
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {pymtstat === "1" && t("paymentSuccess")}
                {pymtstat === "0" && t("paymentFail")}
                {pymtstat === "" && t("fetchingData")}
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 3 }} rowSpacing={3}>
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                {pymtstat === "1" && (
                  <Button
                    sx={{ fontSize: "1.25rem" }}
                    variant="contained"
                    onClick={handleContinueShop}
                    color="success"
                  >
                    {t("continueShopping")}
                  </Button>
                )}
                {pymtstat === "0" && (
                  <Button
                    sx={{ fontSize: "1.25rem" }}
                    variant="outlined"
                    color="error"
                    onClick={handleContinueShop}
                  >
                    {t("payAgain")}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                {pymtstat === "1" && (
                  <Button
                    sx={{ fontSize: "1.25rem", ml: 2 }}
                    variant="contained"
                    onClick={handleMyOrders}
                    color="success"
                  >
                    {t("myOrders")}
                  </Button>
                )}
                {pymtstat === "0" && (
                  <Button
                    sx={{ fontSize: "1.25rem", ml: 2 }}
                    variant="outlined"
                    color="error"
                    onClick={handleMyOrders}
                  >
                    {t("myOrders")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
