import http from "../http-user";

const login = (username, password) => {
  return http.post("/auth/login", JSON.stringify({ username, password }));
};

const signup = (data) => {
  return http.post("/auth/register", data);
};

const logout = () => {
  return http.post("/auth/logout");
};

const getCurrentUser = (jwt) => {
  return http
    .post("/setting/valitade_token.php", {
      jwt,
    })
    .then((response) => {
      return response.data;
    });
};

const exportedObject = {
  login,
  signup,
  getCurrentUser,
  logout,
};

export default exportedObject;
