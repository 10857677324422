import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "./address.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import UserAddrService from "../../services/user-addr-service"; // for user

export default function Address({
  handleCloseModal,
  setAddr,
  setDefAddr,
  opt,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const initialValues = {
    recipient: "",
    mobileno: "",
    addr: "",
    addr2: "",
    addr3: "",
    postcode: "",
    city: "",
    state: "",
    // opt: "1",
    default: false,
    defbill: false,
  };

  const phone = [];
  phone["MY"] = /^[0][1][0-9]{8,11}$/;

  const validationSchema = Yup.object().shape({
    recipient: Yup.string().required(t("isRequired") + " : " + t("recipient")),
    mobileno: Yup.string()
      .required(t("isRequired") + " : " + t("mobileno"))
      .matches(phone["MY"], t("invalid") + ":" + t("phone")),
    addr: Yup.string().required(t("isRequired") + " : " + t("address")),
    addr2: Yup.string().required(t("isRequired") + " : " + t("address") + " 2"),
    postcode: Yup.string().required(t("isRequired") + " : " + t("postcode")),
    city: Yup.string().required(t("isRequired") + " : " + t("city")),
    state: Yup.string().required(t("isRequired") + " : " + t("state")),
  });

  return (
    <div className="addr-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { props, setFieldError, setSubmitting }) => {
          setTimeout(() => {
            if (opt) {
              UserAddrService.update(opt, values)
                .then((response) => {
                  setAddr(response.data);
                  handleCloseModal();
                })
                .catch(() => {
                  setFieldError("recipient", t("faildUpdateAddr"));
                  setSubmitting(false);
                });
            } else {
              UserAddrService.addAddr(values)
                .then((response) => {
                  let alladdr = response.data.alladdr;
                  setAddr(alladdr);

                  if (setDefAddr !== "") {
                    let defaddr = alladdr.filter(
                      (addr) => addr.default_addr === "Y"
                    );
                    setDefAddr(defaddr);
                  }
                  handleCloseModal();
                })
                .catch((e) => {
                  setFieldError("recipient", t("failAddAddr"));
                  setSubmitting(false);
                });
            }
          }, 200);
        }}
      >
        {function ShowForm(formik) {
          useEffect(() => {
            if (opt) {
              UserAddrService.viewbyid(opt)
                .then((response) => {
                  const data = response.data;
                  formik.setFieldValue("recipient", data.receiver, false);
                  formik.setFieldValue("mobileno", data.mobile, false);
                  formik.setFieldValue("addr", data.addr, false);
                  formik.setFieldValue("addr2", data.addr2, false);
                  if (data.addr3) {
                    formik.setFieldValue("addr3", data.addr3, false);
                  }
                  formik.setFieldValue("postcode", data.post_code, false);
                  formik.setFieldValue("city", data.city, false);
                  formik.setFieldValue("state", data.state, false);
                  if (data.default_addr === "Y") {
                    formik.setFieldValue("default", true, false);
                  }
                  if (data.default_bill_addr === "Y") {
                    formik.setFieldValue("defbill", true, false);
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          }, []);

          return (
            <Card sx={{ fontSize: "1.5rem", py: 2, px: 1 }}>
              <CardHeader
                sx={{
                  "& .MuiCardHeader-title": {
                    fontSize: "20px",
                    fontWeight: 300,
                  },
                  "& .MuiCardHeader-action": {
                    "& .MuiSvgIcon-root": {
                      fontSize: "25px",
                    },
                  },
                }}
                action={
                  <IconButton onClick={() => handleCloseModal()}>
                    <HighlightOffIcon />
                  </IconButton>
                }
                title={opt ? t("updateAddr") : t("addNewAddr")}
              />
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "recipient"
                        )}`}
                        id="recipient"
                        name="recipient"
                        variant="outlined"
                        label={t("recipient")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("recipient")}
                        error={
                          formik.touched.recipient &&
                          Boolean(formik.errors.recipient)
                        }
                      />
                      {formik.touched.recipient && formik.errors.recipient && (
                        <FormHelperText
                          id="recipient"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.recipient}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        placeholder={`01xxxxxxxx`}
                        id="mobileno"
                        name="mobileno"
                        variant="outlined"
                        label={t("mobileno")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("mobileno")}
                        error={
                          formik.touched.mobileno &&
                          Boolean(formik.errors.mobileno)
                        }
                      />
                      {formik.touched.mobileno && formik.errors.mobileno && (
                        <FormHelperText
                          id="mobileno"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.mobileno}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t("address")}`}
                        id="addr"
                        name="addr"
                        variant="outlined"
                        label={t("address")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("addr")}
                        error={
                          formik.touched.addr && Boolean(formik.errors.addr)
                        }
                      />
                      {formik.touched.addr && formik.errors.addr && (
                        <FormHelperText
                          id="addr"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.addr}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "address"
                        )} 2`}
                        id="addr2"
                        name="addr2"
                        variant="outlined"
                        label={t("address") + " 2"}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "1.35rem",
                            lineHeight: "1.25rem",
                            fontWeight: "500",
                          },
                        }}
                        fullWidth
                        {...formik.getFieldProps("addr2")}
                        error={
                          formik.touched.addr2 && Boolean(formik.errors.addr2)
                        }
                      />
                      {formik.touched.addr2 && formik.errors.addr2 && (
                        <FormHelperText
                          id="addr2"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.addr2}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "address"
                        )} 3`}
                        id="addr3"
                        name="addr3"
                        variant="outlined"
                        label={t("address") + " 3"}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "1.35rem",
                            lineHeight: "1.25rem",
                            fontWeight: "500",
                          },
                        }}
                        fullWidth
                        {...formik.getFieldProps("addr3")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "postcode"
                        )}`}
                        id="postcode"
                        name="postcode"
                        variant="outlined"
                        label={t("postcode")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("postcode")}
                        error={
                          formik.touched.postcode &&
                          Boolean(formik.errors.postcode)
                        }
                      />
                      {formik.touched.postcode && formik.errors.postcode && (
                        <FormHelperText
                          id="postcode"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.postcode}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t("city")}`}
                        id="city"
                        name="city"
                        variant="outlined"
                        label={t("city")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("city")}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                      />
                      {formik.touched.city && formik.errors.city && (
                        <FormHelperText
                          id="city"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.city}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t("state")}`}
                        id="state"
                        name="state"
                        variant="outlined"
                        label={t("state")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("state")}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                      />
                      {formik.touched.state && formik.errors.state && (
                        <FormHelperText
                          id="state"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.state}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item md={6} xs={12}>
                      <label>
                        <Field type="checkbox" name="default" />
                        <span
                          style={{
                            fontSize: "1.35rem",
                            paddingLeft: "0.45rem",
                            fontWeight: "400",
                          }}
                        >
                          {t("setAsDefault")}
                        </span>
                      </label>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <label>
                        <Field type="checkbox" name="defbill" />
                        <span
                          style={{
                            fontSize: "1.35rem",
                            paddingLeft: "0.45rem",
                            fontWeight: "400",
                          }}
                        >
                          {t("setDefaultBilling")}
                        </span>
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="warning" //-- yck 26.03
                        type="submit"
                        fullWidth
                        sx={{ p: 1, fontSize: "1.5rem" }}
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting && (
                          <CircularProgress size={28} color="success" /> //-- yck 26.03
                        )}
                        {!formik.isSubmitting && opt ? t("Update") : t("Add")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
}
