import http from "../http-public";

const getMenu = (lng) => {
  return http.get(`/util/nav/viewmenu/${lng}`);
};

const exportedObject = {
  getMenu,
};

export default exportedObject;
