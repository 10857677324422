import http from "../http-user";

const view = () => {
  return http.get("/member/address/view");
};

const viewbyid = (addrid) => {
  return http.get(`/member/address/view/${addrid}`);
};

const viewdef = () => {
  return http.get("/member/address/viewdefault");
};

const update = (id, data) => {
  const newdata = { addrid: id, ...data }
  return http.post("/member/address/updaddr", newdata);
};

const remove = (addrid) => {
  return http.delete(`/member/address/remove/${addrid}`);
};

const setdefault = (addrid) => {
  return http.post("/member/address/setdefault", addrid);
};

const setdefbill = (addrid) => {
  return http.post("/member/address/setdefbill", addrid);
};

const addAddr = (data) => {
  return http.post(`/member/address/addaddr`, data);
};

const exportedObject = {
  view,
  viewbyid,
  viewdef,
  update,
  remove,
  setdefault,
  setdefbill,
  addAddr,
};

export default exportedObject;
