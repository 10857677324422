import React from "react";
import "./Products.css";
// import { Link } from "react-router-dom";
import // FavoriteBorder,
// SearchOutlined,
// Visibility,
// ShoppingCartOutlined,
// StarRate,
// StarBorderRounded,
// StarRounded,
"@material-ui/icons";
// import { addProduct } from "../../src/redux/cartRedux";
// import { useDispatch } from "react-redux";
import { useEffect } from "react";
// import { publicRequest } from "../requestMethods";
import { useNavigate, useLocation } from "react-router-dom";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

import { PICTURE_SERVER } from "../constant/constant";

const FeaturedProductsProp = ({ item, typu }) => {
  // const [products, setProducts] = useState([]);
  // const dispatch = useDispatch();
  // const [product, setProduct] = useState({});
  // const location = useLocation();
  let navigate = useNavigate();
  //   const id = location.pathname.split("/")[2];

  useEffect(() => {
    // const getProduct = async () => {
    //     try{
    //         const res = await publicRequest.get("/products/find/" + id);
    //         setProduct(res.data.product);
    //     }catch{}
    // };
    // getProduct()
  }, []);

  const handleClick = (id, opt) => {
    // dispatch(addProduct({...product,}))
    if (opt === "1") {
      navigate(`/product/${id}`);
    } else {
      navigate(`/shops/${id}`);
    }
    // navigate("/address");
  };

  return (
    <div className="feature-images">
      <div style={{ backgroundColor: "#000" }}>
        <img
          src={`${PICTURE_SERVER}${item.pic}`}
          alt=""
          onClick={() => handleClick(item.id, "1")}
        />
      </div>
      {/* <div className="featureCat-icons">
        <div>
          <Visibility
            className="featureIcons"
            onClick={() => handleClick(item.id, "1")}
          />
          <StorefrontTwoToneIcon
            className="featureIcons"
            onClick={() => handleClick(item.shop, "2")}
          />
        </div>
      </div> */}

      <div className="productDesc">
        <div className="shopinfo" onClick={() => handleClick(item.shop, "2")}>
          <StorefrontTwoToneIcon
            style={{
              fontSize: 13,
              color: "orange",
            }}
          />{" "}
          {item.shop_name}
        </div>
        <h2>{item.sku_name}</h2>
        {/* {item.rating} */}
        {typu === "A" ? (
          <h2> RM {item.agent_promo_price}</h2>
        ) : (
          <h2> RM {item.promo_price}</h2>
        )}
      </div>
    </div>
  );
};

export default FeaturedProductsProp;
