import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "./address.css";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import DeliveryService from "../../services/user-delivery-service"; // for user

export default function Delivery({
  handleCloseModal,
  orderno,
  opt,
  refreshData,
}) {
  const { t } = useTranslation();

  const [id, setId] = useState();
  const initialValues = {
    comp: "",
    consno: "",
  };

  const validationSchema = Yup.object().shape({
    comp: Yup.string().required(t("isRequired") + " : " + t("recipient")),
    consno: Yup.string().required(t("isRequired") + " : " + t("state")),
  });

  return (
    <div className="addr-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { props, setFieldError, setSubmitting }) => {
          // console.log("v", values);
          setTimeout(() => {
            const data = {
              ...values,
              ordno: orderno,
            };
            if (opt) {
              DeliveryService.update(id, data)
                .then((response) => {
                  refreshData();
                  handleCloseModal();
                })
                .catch(() => {
                  setFieldError("comp", t("failUpdateDO"));
                  setSubmitting(false);
                });
            } else {
              DeliveryService.addDO(data)
                .then((response) => {
                  refreshData();
                  handleCloseModal();
                })
                .catch((e) => {
                  setFieldError("comp", t("failAddDO"));
                  setSubmitting(false);
                });
            }
          }, 200);
        }}
      >
        {function ShowForm(formik) {
          useEffect(() => {
            if (opt) {
              DeliveryService.viewbyid(orderno)
                .then((response) => {
                  const data = response.data[0];
                  // console.log("delivery", response.data[0]);
                  setId(data.id);
                  formik.setFieldValue("comp", data.dvy_comp, false);
                  formik.setFieldValue("consno", data.dvy_cons_note, false);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          }, []);

          return (
            <Card sx={{ fontSize: "1.5rem", py: 2, px: 1 }}>
              <CardHeader
                sx={{
                  "& .MuiCardHeader-title": {
                    fontSize: "20px",
                    fontWeight: 300,
                  },
                  "& .MuiCardHeader-action": {
                    "& .MuiSvgIcon-root": {
                      fontSize: "25px",
                    },
                  },
                }}
                action={
                  <IconButton onClick={() => handleCloseModal()}>
                    <HighlightOffIcon />
                  </IconButton>
                }
                title={opt ? t("updateDO") : t("addNewDO")}
              />
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "courier_comp"
                        )}`}
                        id="comp"
                        name="comp"
                        variant="outlined"
                        label={t("courier_comp")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("comp")}
                        error={
                          formik.touched.comp && Boolean(formik.errors.comp)
                        }
                      />
                      {formik.touched.recipient && formik.errors.comp && (
                        <FormHelperText
                          id="comp"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.comp}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t("waybill")}`}
                        id="consno"
                        name="consno"
                        variant="outlined"
                        label={t("waybill")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("consno")}
                        error={
                          formik.touched.consno && Boolean(formik.errors.consno)
                        }
                      />
                      {formik.touched.consno && formik.errors.consno && (
                        <FormHelperText
                          id="consno"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.consno}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="warning" //-- yck 26.03
                        type="submit"
                        fullWidth
                        sx={{ p: 1, fontSize: "1.5rem" }}
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting && (
                          <CircularProgress size={28} color="success" /> //-- yck 26.03
                        )}
                        {!formik.isSubmitting && opt ? t("Update") : t("Add")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
}
