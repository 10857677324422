// current still fail to put token in redux state and call from redux

const getLocalRefreshToken = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  if (user) {
    return user?.token;
  } else {
    const userinfo = JSON.parse(localStorage.getItem("persist:root"));
    const userobj = JSON.parse(userinfo.user);
    return userobj.token;
  }
};

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(sessionStorage.getItem("currentUser"));
  if (user) {
    user.token = token;
  } else {
    // get data from local storage; and create the session storage info
    const userinfo = JSON.parse(localStorage.getItem("persist:root"));
    const userobj = JSON.parse(userinfo.user);
    const cartobj = JSON.parse(userinfo.cart);

    user = {
      success: true,
      user: userobj.currentUser,
      token: token,
      cart: cartobj.quantity,
    }
  }
  sessionStorage.setItem("currentUser", JSON.stringify(user));
  // localStorage.setItem("persist:root.user.token", JSON.stringify(token));
};

const getUser = () => {
  let user = JSON.parse(sessionStorage.getItem("currentUser"));
  if (user) {
    return user;
  } else {
    const userinfo = JSON.parse(localStorage.getItem("persist:root"));
    const userobj = JSON.parse(userinfo.user);
    return { user : userobj.currentUser };
  }
};

const setUser = (user) => {
  sessionStorage.setItem("currentUser", JSON.stringify(user));
};

const removeUser = () => {
  sessionStorage.removeItem("currentUser");
};

const getSessionRoleID = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.roleid;
};

const getSessionUsername = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.user;
};

const getSessionUsrCtryId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.usrctrid;
};

const getSessionUsrCtryName = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.usrctrnm;
};

const getSessionUsrCtryCurrency = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.usrctrcr;
};

const getSessionUserShopId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.shopid;
};

const getSessionCtryId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.ctrid;
};

const getSessionBranchId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.branchid;
};

const updateSessionUsrCtryInfo = (usrctrid, ctryid, roleid) => {
  let user = JSON.parse(sessionStorage.getItem("currentUser"));
  user.usrctrid = usrctrid;
  user.ctrid = ctryid;
  user.roleid = roleid;
  sessionStorage.setItem("currentUser", JSON.stringify(user));
};

const getSessionUserType = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.typu;
};

const exportedObject = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getSessionRoleID,
  getSessionUsername,
  getSessionUsrCtryId,
  updateSessionUsrCtryInfo,
  getSessionCtryId,
  getSessionBranchId,
  getSessionUsrCtryName,
  getSessionUsrCtryCurrency,
  getSessionUserShopId,
  getSessionUserType
};

export default exportedObject;
