import * as React from 'react';
import { 
    Grid,
    Card,
    CardActions,
    CardContent,
    TextField,
    Button,
    CircularProgress,
    FormHelperText,
} from '@mui/material';

import { login } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export default function VariableWidthGrid() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const {loginfail} = useSelector((state)=> state.user);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("isRequired") + " : " + t("username")),
    password: Yup.string().required(t("isRequired") + " : " + t("password")),
  });

    const handleSignUp = () => {
      navigate("/signup");
    };

    const handleForgotPassword = () => {};

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { props, setFieldError, setSubmitting }) => {
            setTimeout(() => {
              login(dispatch, values)
                .then(() => {})
                .catch(() => {
                  setFieldError("username", t("userNotExist"));
                  setSubmitting(false);
                });
            }, 200);
          }}
        >
          {(formik) => (
            <Card sx={{ fontSize: "1.5rem", py: 3, px: 2 }}>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <h1>{t("signIn")}</h1>
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={3}>
                    <Grid item xs={12}>
                      {/* <InputLabel htmlFor="username" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("username")}</InputLabel> */}
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "username"
                        )}`}
                        id="username"
                        name="username"
                        variant="outlined"
                        label={t("username")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "1.35rem", lineHeight: "1.25rem" },
                        }}
                        fullWidth
                        {...formik.getFieldProps("username")}
                        error={
                          formik.touched.username &&
                          Boolean(formik.errors.username)
                        }
                      />
                      {formik.touched.username && formik.errors.username && (
                        <FormHelperText
                          id="username"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.username}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {/* <InputLabel htmlFor="password" sx={{fontSize: "1.25rem", fontWeight: "600"}}>{t("password")}</InputLabel> */}
                      <TextField
                        placeholder={`${t("pleaseFillIn")}  :  ${t(
                          "password"
                        )}`}
                        type="password"
                        id="password"
                        name="password"
                        variant="outlined"
                        label={t("password")}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: "1.5rem", padding: "1rem" },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "1.35rem",
                            lineHeight: "1.25rem",
                            fontWeight: "500",
                          },
                        }}
                        fullWidth
                        {...formik.getFieldProps("password")}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                      />
                      {formik.touched.password && formik.errors.password && (
                        <FormHelperText
                          id="username"
                          sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                        >
                          {formik.errors.password}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="warning" //-- yck 26.03
                        type="submit"
                        fullWidth
                        sx={{ p: 1, fontSize: "1.5rem" }}
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting && (
                          <CircularProgress size={28} color="success" /> //-- yck 26.03
                        )}
                        {!formik.isSubmitting && t("login")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
              <CardActions>
                <Button
                  sx={{ fontSize: "1.25rem", color: "#222222" }}
                  onClick={() => handleSignUp()}
                >
                  {t("signUp")}
                </Button>
                <Button
                  sx={{ fontSize: "1.25rem", mr: 2, color: "#222222" }}
                  onClick={() => handleForgotPassword()}
                >
                  {t("forgotPassword")}
                </Button>
              </CardActions>
            </Card>
          )}
        </Formik>
      </div>
    </div>
  );
}