import * as React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  List,
  ListItemText,
  Divider,
  Typography,
  ListItemButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "./address.css";

import { useTranslation } from "react-i18next";

import UserAddrService from "../../services/user-addr-service"; // for user

export default function ExistingAddr({
  handleCloseModal,
  setAddr,
  addrs,
  setDefAddr,
}) {
  const { t } = useTranslation();

  const handleChosenAddr = (addrid) => {
    UserAddrService.setdefault({ addrid })
      .then((response) => {
        let alladdr = response.data;
        // console.log("add", response.data);
        setAddr(alladdr);
        let defaddr = alladdr.filter((addr) => addr.default_addr === "Y");
        setDefAddr(defaddr);
        handleCloseModal();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="login-wrapper">
      <Card sx={{ fontSize: "1.5rem" }}>
        <CardHeader
          sx={{
            "& .MuiCardHeader-title": {
              fontSize: "20px",
              fontWeight: 300,
            },
            "& .MuiCardHeader-action": {
              "& .MuiSvgIcon-root": {
                fontSize: "25px",
              },
            },
          }}
          action={
            <IconButton onClick={() => handleCloseModal()}>
              <HighlightOffIcon />
            </IconButton>
          }
          title={t("existingAddr")}
        />
        <CardContent>
          <span style={{ fontSize: "1.25rem" }}>{t("clickToSetDefault")}</span>
          <List
            component="nav"
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: "70vh",
            }}
          >
            {addrs.map((addr) => (
              <React.Fragment key={addr.id}>
                <ListItemButton
                  onClick={() => handleChosenAddr(addr.id)}
                >
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "1.4rem" }}
                    primary={addr.receiver + " - " + addr.mobile}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "block", fontSize: "1.4rem" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {addr.addr},
                        </Typography>
                        <Typography
                          sx={{ display: "block", fontSize: "1.4rem" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {addr.addr2},
                        </Typography>
                        <Typography
                          sx={{ display: "block", fontSize: "1.4rem" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {addr.addr3 ? addr.addr3 : ""}
                        </Typography>
                        <Typography
                          sx={{ display: "block", fontSize: "1.4rem" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {addr.post_code} {addr.city}, {addr.state}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </div>
  );
}
