import React, { useState, useEffect, useCallback } from "react";
import Footer from "../footer";
import "./memborderDetail.css";
import styled from "styled-components";
import { logout } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";

import undraw_empty_cart_co35 from "../../undraw_empty_cart_co35.png";
import { useNavigate, useParams } from "react-router-dom";

import {
  // Box,
  // Modal,
  Snackbar,
  // Backdrop,
  // CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { PICTURE_SERVER } from "../../constant/constant";

// import UserCartService from "../../services/user-cart-service"; // for user
import UserOrderService from "../../services/user-order-service"; // for user

const Topbutton = styled.button`
  padding: 10px;
  font-weight: ${(props) => (props.lng === "cn" ? "500" : "600")};
  letter-spacing: ${(props) => (props.lng === "cn" ? "1.5px" : "normal")};
  cursor: pointer;
  border: ${(props) => props.type === "filter" && "none"};
  background-color: ${(props) =>
    props.type === "filter" ? "#278441" : "tranparent"};
  color: ${(props) => props.type === "filter" && "white"};
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrderDetail = () => {
  // const user = useSelector((state) => state.user.currentUser);
  const { orderno } = useParams();
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [order, setOrder] = useState({});
  // const [products, setProducts] = useState([]);
  // const [billaddr, setBillAddr] = useState({});
  // const [prodcnt, setProdCnt] = useState(0);
  // const [subamt, setSubamt] = useState(0);
  const [openCfscr, setopenCfscr] = useState(false);
  const [actionopt, setActionOpt] = useState("");
  const [actionid, setActionId] = useState("");

  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "success",
  });

  const { vertical, horizontal, open, message, severity } = msg;

  // const [openBD, setOpenBD] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const handleCloseDlg = (e) => {
    if (e.target.value === "Y") {
      setTimeout(() => {
        if (actionopt === "1") {
          // for product cancel
          UserOrderService.cancelItem(actionid)
            .then((response) => {
              refreshData();
              setopenCfscr(false);
              // setMsg({ ...msg, open: true });
            })
            .catch((e) => {
              if (e?.response?.status === 400) {
                logout(dispatch)
                  .then(() => {
                    navigate("/login");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                console.log(e);
              }
            });
        } else if (actionopt === "2") {
          // for bill / order cancel
          UserOrderService.cancelOrder(actionid)
            .then((response) => {
              refreshData();
              setopenCfscr(false);
              // setMsg({ ...msg, open: true });
            })
            .catch((e) => {
              if (e?.response?.status === 400) {
                logout(dispatch)
                  .then(() => {
                    navigate("/login");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                console.log(e);
              }
            });
        } else if (actionopt === "3") {
          // for order confirmation
          UserOrderService.confirmOrder(actionid)
            .then((response) => {
              refreshData();
              setopenCfscr(false);
              setMsg({ ...msg, open: true });
            })
            .catch((e) => {
              if (e?.response?.status === 400) {
                logout(dispatch)
                  .then(() => {
                    navigate("/login");
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                console.log(e);
              }
            });
        } else if (actionopt === "4") {
          // for order return refund
          navigate(`/applyrefund/${actionid}`);
        } else if (actionopt === "5") {
          // for product return refund
          // UserOrderService.refundItem(actionid)
          //   .then((response) => {
          // refreshData();
          //     setopenCfscr(false);
          //     setMsg({ ...msg, open: true });
          //   })
          // .catch((e) => {
          //     if (e?.response?.status === 400) {
          //       logout(dispatch)
          //         .then(() => {
          //           navigate("/login");
          //         })
          //         .catch((e) => {
          //           console.log(e);
          //         });
          //     } else {
          //       console.log(e);
          //     }
          //   });
        }
      }, 200);
    }
    setopenCfscr(false);
  };

  const handleContinueShop = () => {
    navigate("/");
  };

  const handleMyOrder = (stat) => {
    let tab = 0;
    switch (stat) {
      case 0:
        tab = 5;
        break; // cancelled
      case 1:
        tab = 1;
        break; // to pay
      case 2:
        tab = 2;
        break; // to ship
      case 3:
        tab = 3;
        break; // to receive
      case 4:
        tab = 4;
        break; // to completed
      case 6:
      case 7:
        tab = 6;
        break; // return refund
      default:
        tab = 0; // all
    }
    navigate(`/order/${tab}`);
  };

  // const handleItemCancel = (itemid) => {
  //   setActionOpt("1");
  //   setActionId(itemid);
  //   setopenCfscr(true);
  // };

  // const handleItemRefund = (itemid) => {
  //   setActionOpt("5");
  //   setActionId(itemid);
  //   setopenCfscr(true);
  // };

  const handleOrderCancel = () => {
    setActionOpt("2");
    setActionId(orderno);
    setopenCfscr(true);
  };

  const handleOrderReceived = () => {
    setActionOpt("3");
    setActionId(orderno);
    setopenCfscr(true);
  };

  const handleOrderRefund = () => {
    setActionOpt("4");
    setActionId(orderno);
    setopenCfscr(true);
  };

  const handlePayNow = (addrid) => {
    navigate(`/checkoutpymt/${orderno}/${addrid}/2`);
    // console.log("addrid", addrid);
  };

  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      mins = "" + d.getMinutes(),
      sec = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (mins.length < 2) mins = "0" + mins;
    if (sec.length < 2) sec = "0" + sec;

    return [day, month, year].join("/") + " " + hour + ":" + mins + ":" + sec;
  }

  const refreshData = useCallback(() => {
    UserOrderService.viewById(orderno, curLang)
      .then((response) => {
        // console.log("res", response.data);
        setOrder(response.data);
        // setProducts(response.data.info.order_item);
        // setBillAddr(response.data.billaddr);
        // setProdCnt(response.data.cnt);
        // setSubamt(+response.data.tot);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          logout(dispatch)
            .then(() => {
              navigate("/login");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(e);
        }
      });
  }, [orderno]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <>
      {Object.keys(order).length === 0 && order.constructor === Object ? (
        <div className="orderdet-empty">
          <img src={undraw_empty_cart_co35} alt="" />
        </div>
      ) : (
        <div className="orderdet-container">
          <div className="orderdet-wrapper">
            <h1>{t("orderDetail")}</h1>
            <div className="top">
              <div className="topText">
                <Topbutton
                  type="filter"
                  lng={curLang}
                  onClick={() => handleMyOrder(order.info.ostatus)}
                >
                  {t("myOrders")}
                </Topbutton>
                <Topbutton
                  type="filter"
                  lng={curLang}
                  onClick={handleContinueShop}
                  style={{ marginLeft: "10px" }}
                >
                  {t("continueShopping")}
                </Topbutton>
              </div>
              <div className="topText">
                <h1>
                  {order.info.ostatus === 1 && t("topay")}
                  {order.info.ostatus === 2 && t("toship")}
                  {order.info.ostatus === 3 && t("toreceive")}
                  {order.info.ostatus === 4 && t("completed")}
                  {order.info.ostatus === 0 && t("cancelled")}
                  {order.info.ostatus === 6 && t("partialrefund")}
                  {order.info.ostatus === 7 && t("refund")}
                </h1>
              </div>
            </div>
            <div className="bottom">
              <div className="info">
                <div className="product">
                  <div className="productAddress">
                    <div className="details">
                      <div className="productName">
                        <b>{t("deliveryto")} : </b>
                      </div>
                      <div className="productName">
                        {t("recipient")} : {order.info.order_address.receiver}
                      </div>
                      <div className="productName">
                        {t("mobileno")} : {order.info.order_address.mobile}
                      </div>
                      <div className="productName">
                        {order.info.order_address.addr},
                      </div>
                      <div className="productName">
                        {order.info.order_address.addr2},
                      </div>
                      <div className="productName">
                        {order.info.order_address.addr3
                          ? order.info.order_address.addr3
                          : ""}
                      </div>
                      <div className="productName">
                        {order.info.order_address.post_code}{" "}
                        {order.info.order_address.city},{" "}
                        {order.info.order_address.state}
                      </div>
                    </div>
                  </div>
                  <div className="productAddress">
                    <div className="details">
                      <div className="productName">
                        <b>{t("billto")} :</b>
                      </div>
                      <div className="productName">
                        {t("recipient")} : {order.billaddr.receiver}
                      </div>
                      <div className="productName">
                        {t("mobileno")} : {order.billaddr.mobile}
                      </div>
                      <div className="productName">{order.billaddr.addr},</div>
                      <div className="productName">{order.billaddr.addr2},</div>
                      <div className="productName">
                        {order.billaddr.addr3 ? order.billaddr.addr3 : ""}
                      </div>
                      <div className="productName">
                        {order.billaddr.post_code} {order.billaddr.city},{" "}
                        {order.billaddr.state}
                      </div>
                    </div>
                  </div>
                  <div className="productAddress">
                    <div className="details">
                      <div className="productName">
                        {t("orderdate") +
                          " : " +
                          formatDate(order.info.created_at)}
                      </div>
                      <div className="productName">
                        {t("invoiceno")} : {order.info.invoice_num}
                      </div>
                      {order.info.cancel_time && (
                        <div className="productName">
                          {t("cancelleddate") +
                            " : " +
                            formatDate(order.info.cancel_time)}
                        </div>
                      )}
                      {order.info.pay_time && (
                        <div className="productName">
                          {t("invoicedate") +
                            " : " +
                            formatDate(order.info.pay_time)}
                        </div>
                      )}
                      {order.info.dvy_time && (
                        <div className="productName">
                          {t("deliverydate") +
                            " : " +
                            formatDate(order.info.dvy_time)}
                        </div>
                      )}
                      {order.info.finally_time && (
                        <div className="productName">
                          {t("completeddate") +
                            " : " +
                            formatDate(order.info.finally_time)}
                        </div>
                      )}
                      {/* {order.info.finally_time && (
                        <div className="productName">
                          t("refundeddate") + " : " + new
                          Date(order.info.finally_time).toLocaleString()
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                {order.info.order_item.map((product) => (
                  <div className="product" key={product.id}>
                    <div className="productDetail">
                      <img src={`${PICTURE_SERVER}${product.pic}`} alt="" />
                      <div className="details">
                        <div className="shopinfo">
                          <StorefrontTwoToneIcon
                            style={{
                              fontSize: 15,
                              color: "orange",
                            }}
                          />{" "}
                          {product.shop_name}
                        </div>
                        <div className="productName">
                          <b>{t("product")} :</b> {product.sku_name}
                        </div>
                        <div className="productID">
                          <b>{t("unitPrice")} :</b> RM{" "}
                          {new Intl.NumberFormat("en-MY", {
                            minimumFractionDigits: 2,
                          }).format(product.unit_price)}
                        </div>
                        {product.uom1 && product.uom1 !== "std" && (
                          <div className="productSize">
                            <b>{t(product.uom1)} : </b>
                            {product.uom1desc}
                          </div>
                        )}
                        {product.uom2 && product.uom2 !== "std" && (
                          <div className="productSize">
                            <b>{t(product.uom2)} : </b>
                            {product.uom2desc}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="priceDetail">
                      <div className="productAmountContainer">
                        <div className="productAmount">x {product.qty}</div>
                      </div>
                      <div className="productPrice">
                        RM{" "}
                        {new Intl.NumberFormat("en-MY", {
                          minimumFractionDigits: 2,
                        }).format(product.total_amount)}
                      </div>
                      {/* <div>
                        {product.istatus === -1 && (
                          <Button
                            sx={{ fontSize: "12px" }}
                            color="error"
                            onClick={() => handleItemCancel(product.id)}
                          >
                            {t("cancelthisprod")}
                          </Button>
                        )}
                        {(product.istatus === 2 || product.istatus === 3) && (
                          <Button
                            sx={{ fontSize: "12px" }}
                            color="error"
                            onClick={() => handleItemRefund(product.id)}
                          >
                            {t("refundthisprod")}
                          </Button>
                        )}
                        {product.istatus === 0 && (
                          <Button sx={{ fontSize: "12px" }} color="error">
                            {t("cancelled")}
                          </Button>
                        )}
                        {product.istatus === 6 && (
                          <Button sx={{ fontSize: "12px" }} color="primary">
                            {t("requestedrefund")}
                          </Button>
                        )}
                        {product.istatus === 7 && (
                          <Button sx={{ fontSize: "12px" }} color="primary">
                            {t("acceptedrefund")}
                          </Button>
                        )}
                        {product.istatus === 8 && (
                          <Button sx={{ fontSize: "12px" }} color="primary">
                            {t("pendingrefund")}
                          </Button>
                        )}
                        {product.istatus === 9 && (
                          <Button sx={{ fontSize: "12px" }} color="success">
                            {t("donerefund")}
                          </Button>
                        )}
                        {product.istatus === 10 && (
                          <Button sx={{ fontSize: "12px" }} color="error">
                            {t("rejectedrefund")}
                          </Button>
                        )}
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
              <div className="summary">
                <div className="summaryTitle">{t("orderSummary")}</div>
                <div className="summaryItem">
                  <div className="summaryItemText">{t("subtotal")} :</div>
                  <div className="summaryItemText">
                    RM{" "}
                    {new Intl.NumberFormat("en-MY", {
                      minimumFractionDigits: 2,
                    }).format(order.info.prod_amount)}
                  </div>
                </div>
                <div className="summaryItem">
                  <div className="summaryItemText">{t("shipping")} :</div>
                  <div className="summaryItemText">
                    RM{" "}
                    {new Intl.NumberFormat("en-MY", {
                      minimumFractionDigits: 2,
                    }).format(order.info.delivery_amount)}
                  </div>
                </div>
                <div className="summaryItemBold">
                  <div className="summaryItemText">{t("total")}</div>
                  <div className="summaryItemText">
                    RM{" "}
                    {new Intl.NumberFormat("en-MY", {
                      minimumFractionDigits: 2,
                    }).format(order.info.total)}
                  </div>
                </div>
                {order.info.ostatus === 1 && (
                  <button
                    className="summaryButton"
                    onClick={() =>
                      handlePayNow(order.info.order_address.addr_id)
                    }
                    style={{
                      color: "white",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                      letterSpacing: curLang === "cn" ? "1.5px" : "normal",
                    }}
                  >
                    {t("payNow")}
                  </button>
                )}
                {order.info.ostatus === 2 && (
                  <div className="footerinfo">
                    <button
                      className="summaryButton"
                      style={{
                        color: "white",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        letterSpacing: curLang === "cn" ? "1.5px" : "normal",
                      }}
                      onClick={handleOrderReceived}
                    >
                      {t("orderreceived")}
                    </button>
                  </div>
                )}
                {(order.info.ostatus === 2 ||
                  order.info.ostatus === 3 ||
                  order.info.ostatus === 6) && (
                  <div className="footerinfo">
                    <Topbutton
                      type="none"
                      lng={curLang}
                      style={{ marginTop: "1rem", width: "100%" }}
                      onClick={handleOrderRefund}
                    >
                      {t("requestrefund")}
                    </Topbutton>
                  </div>
                )}
                {order.info.ostatus === 1 && (
                  <div className="footerinfo">
                    <Topbutton
                      type="none"
                      lng={curLang}
                      style={{ marginTop: "1rem", width: "100%" }}
                      onClick={handleOrderCancel}
                    >
                      {t("cancelwholeorder")}
                    </Topbutton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Dialog
        open={openCfscr}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "16px",
          }}
        >
          {t("confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "16px",
            }}
          >
            {actionopt === "1" && t("itemCancelConfirmation")}
            {actionopt === "2" && t("orderCancelConfirmation")}
            {actionopt === "3" && t("orderReceiveConfirmation")}
            {actionopt === "4" && t("orderRefundConfirmation")}
            {actionopt === "5" && t("itemRefundConfirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDlg}
            value="N"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("no")}
          </Button>
          <Button
            onClick={handleCloseDlg}
            value="Y"
            autoFocus
            variant="contained"
            color="warning"
            sx={{ fontSize: "1.25rem" }}
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderDetail;
