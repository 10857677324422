import http from "../http-public";

const getFeaturedCart = (lng) => {
  return http.get(`/util/cart/viewfeatured/${lng}`);
};

const getProductInfo = (id, lng) => {
  return http.get(`/util/cart/viewproduct/${id}/${lng}`);
};

const getShopInfo = (cat) => {
  return http.get(`/util/cart/getshop/${cat}`);
};

const getProdByCategory = (cat, lng) => {
  return http.get(`/util/cart/getprodbycat/${cat}/${lng}`);
};

const getCategoryInfo = (shopid, lng) => {
  return http.get(`/util/cart/getcate/${shopid}/${lng}`);
};

// cat = shopid
const getProdByShop = (cat, lng) => {
  return http.get(`/util/cart/getshopprod/${cat}/${lng}`);
};

const getSearchProd = (keyword, lng) => {
  return http.get(`/util/cart/getsearchprod/${keyword}/${lng}`);
};

const exportedObject = {
  getFeaturedCart,
  getProductInfo,
  getShopInfo,
  getProdByCategory,
  getCategoryInfo,
  getProdByShop,
  getSearchProd,
};

export default exportedObject;
