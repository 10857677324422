import React, { useState, useEffect } from "react";
import "./membprofile.css";
import { logout } from "../../redux/apiCalls";

import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserService from "../../services/user-service";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const MemberPassword = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user.currentUser);

  const [msg, setMsg] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const { vertical, horizontal, open, message } = msg;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsg({ ...msg, open: false });
  };

  const initialValues = {
    currpasswd: "",
    newpasswd: "",
  };

  const validationSchema = Yup.object().shape({
    currpasswd: Yup.string()
      .min(6, t("Minimum 6 alphanumeric characters"))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,24}$/,
        t("Must Be In Alphanumeric characters")
      )
      .required(t("isRequired") + " : " + t("password")),
    newpasswd: Yup.string()
      .min(6, t("Minimum 6 alphanumeric characters"))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,24}$/,
        t("Must Be In Alphanumeric characters")
      )
      .required(t("isRequired") + " : " + t("password")),
  });

  // const {isFetching, loginfail} = useSelector((state)=> state.user)

  return (
    <>
      <div className="signup-container">
        <div className="signup-wrapper">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { props, setFieldError, setSubmitting }) => {
              setTimeout(() => {
                UserService.resetPassword(values)
                  .then((response) => {
                    setMsg({
                      ...msg,
                      open: true,
                      message: `${t("pswdUpdSuccess")}`,
                    });
                    setSubmitting(false);
                  })
                  .catch((e) => {
                    if (e?.response?.status === 400) {
                      logout(dispatch)
                        .then(() => {
                          navigate("/login");
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    } else {
                      setFieldError("currpasswd", e);
                      setSubmitting(false);
                    }
                  });
              }, 200);
            }}
          >
            {function ShowForm(formik) {
              return (
                <Card sx={{ fontSize: "1.5rem", py: 3, px: 2 }}>
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <h1>{t("changePassword")}</h1>
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={3} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            placeholder={`${t("pleaseFillIn")}  :  ${t(
                              "existingPassword"
                            )}`}
                            type="currpasswd"
                            id="currpasswd"
                            name="currpasswd"
                            variant="outlined"
                            label={t("existingPassword")}
                            InputProps={{
                              inputProps: {
                                style: {
                                  fontSize: "1.5rem",
                                  padding: "1rem",
                                  lineHeight: "1.5rem",
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("currpasswd")}
                            error={
                              formik.touched.currpasswd &&
                              Boolean(formik.errors.currpasswd)
                            }
                          />
                          {formik.touched.currpasswd &&
                            formik.errors.currpasswd && (
                              <FormHelperText
                                id="currpasswd"
                                sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                              >
                                {formik.errors.currpasswd}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            placeholder={`${t("pleaseFillIn")}  :  ${t(
                              "newPassword"
                            )}`}
                            type="newpasswd"
                            id="newpasswd"
                            name="newpasswd"
                            variant="outlined"
                            label={t("newPassword")}
                            InputProps={{
                              inputProps: {
                                style: {
                                  fontSize: "1.5rem",
                                  padding: "1rem",
                                  lineHeight: "1.5rem",
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "1.35rem",
                                lineHeight: "1.25rem",
                              },
                            }}
                            fullWidth
                            {...formik.getFieldProps("newpasswd")}
                            error={
                              formik.touched.newpasswd &&
                              Boolean(formik.errors.newpasswd)
                            }
                          />
                          {formik.touched.newpasswd &&
                            formik.errors.newpasswd && (
                              <FormHelperText
                                id="newpasswd"
                                sx={{ fontSize: "1.15rem", color: "#E0144C" }}
                              >
                                {formik.errors.newpasswd}
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={3} spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="warning"
                            type="submit"
                            fullWidth
                            sx={{ p: 1, fontSize: "1.5rem" }}
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting && (
                              <CircularProgress size={28} color="success" />
                            )}
                            {!formik.isSubmitting && t("Update")}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              );
            }}
          </Formik>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%", fontSize: "1.325rem" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MemberPassword;
