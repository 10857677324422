import {
  Search,
  ShoppingCartOutlined,
  ArrowDropDown,
  Menu,
  Close,
  // PermIdentityOutlined,
  // LockOpen,
  Language,
  AccountCircle,
  Person,
  ShoppingCart,
  AccountBalanceWallet,
  LocalAtm,
  LockOpen,
} from "@material-ui/icons";
import AddHomeIcon from "@mui/icons-material/AddHome";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect } from "react";
import "./navbar.css";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import LanguageIcon from '@mui/icons-material/Language';

import CategoryItems from "./categoryProp";
import CategoryItemsMobile from "./categoryPropMobile";

// import {gsap} from 'gsap'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import FeaturedProductsProp from "./ProductsProp";
// import axios from "axios";
import { logout } from "../redux/apiCalls";
// import { useParams } from 'react-router-dom';
import { SERVER_DOMAIN } from "../constant/constant";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import SubLogin from "./component/sublogin";

import logo from "../logo.png";
import NaviService from "../services/navi-service";

const Navbar = () => {
  const { t } = useTranslation();
  const quantity = useSelector((state) => state.cart.quantity);
  // const [products, setProducts] = useState([])
  const [menudata, setMenuData] = useState([]);
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState();
  const [curLangName, setCurLangName] = useState("");

  let navigate = useNavigate();

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "transparent",
  };

  const languages = [
    {
      code: "en",
      name: "ENG",
      country_code: "gb",
    },
    {
      code: "cn",
      name: "中文",
      country_code: "cn",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";

  const user = useSelector((state) => state.user.currentUser);

  const noLoginSignup = "";

  const noLogout = "";

  useEffect(() => {
    NaviService.getMenu(currentLanguageCode)
      .then((response) => {
        setMenuData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [currentLanguageCode]);

  useEffect(() => {
    const langs = [
      {
        code: "en",
        name: "ENG",
        country_code: "gb",
      },
      {
        code: "cn",
        name: "中文",
        country_code: "cn",
      },
    ];

    let newArr = langs.filter((lang) => lang.code === currentLanguageCode);
    setCurLangName(newArr[0]["name"]);
  }, [currentLanguageCode]);

  const useStyles = makeStyles((theme) => ({
    badge: {
      backgroundColor: "#67c82a",
      color: "white",
      fontWeight: 500,
      fontSize: "1rem",
    },
  }));
  const classes = useStyles();

  const [CatNav, setCatNav] = useState(false);

  const showCatNav = () => setCatNav(!CatNav);

  const handleClick = (opt) => {
    switch (opt) {
      case "1":
        navigate("/editprofile");
        break;
      case "2":
        navigate("/order/0");
        break;
      case "3":
        break;
      case "4":
        break;
      case "5":
        navigate("/address");
        break;
      case "6":
        navigate("/editpswd");
        break;
      case "7":
        logout(dispatch);
        // cookies.remove("MyCookieName", { path: "/", domain: SERVER_DOMAIN }); // not working
        break;
      default:
        navigate("/");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword) {
      let str = keyword.trim();
      navigate(`/search/${str}`);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="containner">
        <div className="navbar">
          <div className="menu-bars">
            <Menu className="icon" onClick={showCatNav} />
          </div>

          <Link to="/home" className="logo-container">
            {/* <h1 className="logo">MKCK</h1> */}
            <img src={logo} alt="" />
          </Link>

          {/* Search */}
          <form onSubmit={submitHandler}>
            <input
              type="text"
              onChange={(e) => setKeyword(e.target.value)}
              placeholder={t("search_here") + "..."}
            />
            <button type="submit">
              <Search className="search-icon" />
            </button>
          </form>

          <div className="menuNew">
            <div className="li">
              <div className="menu-wrap">
                <div className="menu-text">
                  {t("products")}
                  <ArrowDropDown />
                </div>
              </div>
              <div className="subMenu">
                {menudata.map((item, index) => {
                  return <CategoryItems item={item} key={index} />;
                })}
              </div>
            </div>
            {user ? (
              <div className="li">
                <div className="menu-wrap">
                  <AccountCircle color="action" className="menuIcon" />
                  <div className="menu-text">
                    {t("account")}
                    <ArrowDropDown />
                  </div>
                </div>
                <div className="subMenu22">
                  <div className="li" onClick={() => handleClick("1")}>
                    <div className="menu-wrap">
                      <Person color="action" className="menuIcon" />
                      <Link to={`/editprofile`}>{t("myProfile")}</Link>
                    </div>
                  </div>
                  <div className="li" onClick={() => handleClick("2")}>
                    <div className="menu-wrap">
                      <ShoppingCart color="action" className="menuIcon" />
                      <Link to={`/login`}>{t("myOrders")}</Link>
                    </div>
                  </div>
                  <div className="li" onClick={() => handleClick("3")}>
                    <div className="menu-wrap">
                      <AccountBalanceWallet
                        color="action"
                        className="menuIcon"
                      />
                      <Link to={`/login`}>{t("myWallet")}</Link>
                    </div>
                  </div>
                  <div className="li" onClick={() => handleClick("4")}>
                    <div className="menu-wrap">
                      <LocalAtm color="action" className="menuIcon" />
                      <Link to={`/login`}>{t("myVouchers")}</Link>
                    </div>
                  </div>
                  <div className="li" onClick={() => handleClick("5")}>
                    <div className="menu-wrap">
                      <AddHomeIcon color="action" className="menuIcon" />
                      <Link to={`/login`}>{t("myAddress")}</Link>
                    </div>
                  </div>
                  <div className="li" onClick={() => handleClick("6")}>
                    <div className="menu-wrap">
                      <LockOpen color="action" className="menuIcon" />
                      <Link to={`/editpswd`}>{t("changePassword")}</Link>
                    </div>
                  </div>
                  <div className="li" onClick={() => handleClick("7")}>
                    <div className="menu-wrap">
                      <LogoutIcon color="action" className="menuIcon" />
                      <Link>{t("logout")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              noLogout
            )}
            {user ? (
              noLoginSignup
            ) : (
              <Link to="/signup/0" className="li">
                {t("signUp")}
              </Link>
            )}
            {user ? (
              noLoginSignup
            ) : (
              <Link className="li" onClick={handleOpenModal}>
                {t("signIn")}
              </Link>
            )}
            <div className="li">
              <div className="menu-wrap">
                <Language color="action" className="menuIcon" />
                <div className="menu-text">
                  {curLangName}
                  {/* {currentLanguageCode} */}
                  <ArrowDropDown />
                </div>
              </div>
              <div className="subMenu21">
                {languages.map(
                  ({ code, name, country_code }) =>
                    currentLanguageCode !== code && (
                      <div
                        className="li"
                        key={country_code}
                        onClick={() => {
                          i18n.changeLanguage(code);
                          cookies.set("i18next", code); //, {domain: SERVER_DOMAIN, sameSite: "Lax", path: "/",});
                          setCurLangName(name);
                        }}
                      >
                        <Link>{name}</Link>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>

          <div className="huo">
            <Link to="/cart" className="cart-wrap">
              <Badge
                overlap="rectangular"
                badgeContent={quantity}
                classes={{ badge: classes.badge }}
                className="badge"
              >
                <ShoppingCartOutlined color="action" className="cartColor" />
              </Badge>
              <div className="cart-text">{t("cart")}</div>
            </Link>
          </div>
        </div>

        {/* <>MOBILE VIEW</> */}

        <div className={CatNav ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <div className="menu-barss">
                <Close className="icon" onClick={showCatNav} />
              </div>
            </li>

            <ul className="mob-menu">
              <li>
                <Language
                  style={{
                    marginLeft: "15px",
                    marginRight: "10px",
                    fontSize: "1.75rem",
                  }}
                />
                <Select
                  size="small"
                  labelId="languagesmob"
                  id="languagesmob"
                  value={currentLanguageCode}
                  variant="standard"
                  sx={{ fontSize: "1.35rem" }}
                >
                  {languages.map(({ code, name, country_code }) => (
                    <MenuItem
                      key={country_code}
                      value={code ? code : " "}
                      onClick={() => {
                        i18n.changeLanguage(code);
                        cookies.set("i18next", code); // , {domain: SERVER_DOMAIN, sameSite: "Lax", path: "/"});
                      }}
                      disabled={code === currentLanguageCode}
                      sx={{ fontSize: "1.35rem" }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </li>
            </ul>

            <ul className="mob-menu">
              {/* {user ? noLoginSignup : <li onClick={showCatNav}><Link to="/login" className="nav-text" ><LockOpen style={{marginRight:"10px", fontSize:"1.5rem"}}/>login</Link></li> }

          {user ? noLoginSignup : <li onClick={showCatNav}><Link to="/signUp" className="nav-text"><PermIdentityOutlined style={{marginRight:"10px", fontSize:"1.5rem"}}/>sign up</Link></li> }

          {user ? <li onClick={handleLogout}><Link className="nav-text"><LockOpen style={{marginRight:"10px", fontSize:"1.5rem"}}/>My Account</Link></li> : noLogout }
          {user ? <li onClick={handleLogout}><Link className="nav-text"><LockOpen style={{marginRight:"10px", fontSize:"1.5rem"}}/>Logout</Link></li> : noLogout } */}

              <li className="cat">
                <Link>{t("products")}</Link>
              </li>

              {menudata.map((item, index) => {
                return <CategoryItemsMobile item={item} key={index} />;
              })}
            </ul>
          </ul>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SubLogin handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;
