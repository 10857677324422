import React, { useEffect, useState } from "react";
// import { CategoryMenus } from "./Data";
// import womanClothe1 from "./images/imagesWoman/womanClothe1.jpg";
// import CategoriesShoes from "./images/imagesCategories/CategorieShoes.jpg";
// import bagsAndWallets from "./images/imagesCategories/bagsAndWallets.jpg";
// import watches from "./images/imagesCategories/watches.jpg";
// import Jewelry from "./images/imagesCategories/jewelry.jpg";
// import sunglasses from "./images/imagesCategories/sunglasses.jpg";
// import textile from "./images/imagesCategories/textile.jpg";
// import luggage from "./images/imagesCategories/luggage.jpg";
import "./Products.css";
// import womanShoe2 from "./images/imagesWoman/womanShoe2.jpg";
// import {
//   FavoriteBorder,
//   SearchOutlined,
//   ShoppingCartOutlined,
//   StarRate,
//   StarBorderRounded,
//   StarRounded,
// } from "@material-ui/icons";
// import { ProductsData } from "./Data";
import FeaturedProductsProp from "./ProductsProp";
// import axios from "axios";
// import ProductList from "./pages/productList";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import CartService from "../services/cart-service";

const FeaturedProducts = ({ cat, filters, sort }) => {
  const { t } = useTranslation();
  const curLang = cookies.get("i18next") || "en";
  const typu = useSelector((state) => state.user.typu);

  const [products, setProducts] = useState([]);
  const [featuredCollection, setFeaturedCollection] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  //API call for featured collection on homepage
  useEffect(()=>{
    CartService.getFeaturedCart(curLang)
      .then((response) => {
        setFeaturedCollection(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [curLang]);


  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.promo_price - b.promo_price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.promo_price - b.promo_price)
      );
    }
  }, [sort]);

  return (
    <div className="featured-container">
      <h1>
        {t("featured")}
        <span style={{ color: "#278441" }}> {t("collection")}</span>
      </h1>
      <div className="image-container">
        {featuredCollection.map((item) => (
          <FeaturedProductsProp item={item} typu={typu} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedProducts;