import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  baseURL: "https://ninjahouseware.my/mkckcartt",
  // baseURL: "http://localhost:81/mkckcart",
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    // const originalConfig = err.config;

    // if (originalConfig.url !== "/auth" && err.response) {
    //   // Access Token was expired
    //   if (err.response.status === 401 && !originalConfig._retry) {
    //     // sessionStorage.removeItem("currentUser");
    //     // window.location = `${process.env.PUBLIC_URL}/auth`;
    //     originalConfig._retry = true;
    //     try {
    //       //const rs = await instance.post("/auth/refresh_token", {refreshToken: TokenService.getLocalRefreshToken(),});
    //       const { user, shopid } = TokenService.getUser();
    //       const rs = await instance.post(
    //         "/auth/refresh_token",
    //         JSON.stringify({ user, shopid })
    //       );
    //       const { token } = rs.data;

    //       TokenService.updateLocalAccessToken(token);
    //       return instance(originalConfig);
    //     } catch (_error) {
    //       return Promise.reject(_error);
    //     }
    //   }
    // }
    return Promise.reject(err);
  }
);

export default instance;
