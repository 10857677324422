import * as React from "react";
import {
  Paper,
  // CssBaseline,
  Box,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  let navigate = useNavigate();
  const goTo = (nV) => {
    if (nV === 0) {
      navigate("/");
    } else if (nV === 1) {
      navigate("/cart");
    } else if (nV === 2) {
      navigate("/login");
    } else {
      navigate("/");
    }
  };

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      {/* <CssBaseline /> */}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: "100" }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            goTo(newValue);
          }}
          sx={{
            paddingTop: "3rem",
            paddingBottom: "3.25rem",
            boxShadow: "1rem",
            "& .MuiBottomNavigationAction-root": {
              "& .MuiSvgIcon-root": {
                // color: theme => theme.palette.warning.main,
                fontSize: "2.5rem",
              },
              " & .MuiBottomNavigationAction-label": {
                // color: theme => theme.palette.secondary.main,
                fontSize: "1.25rem",
              },
            },
            "& .Mui-selected": {
              "& .MuiBottomNavigationAction-label": {
                transition: "none",
                fontWeight: "bold",
              },
              "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
                color: (theme) => theme.palette.warning.main,
              },
            },
          }}
        >
          <BottomNavigationAction label={t("home")} icon={<HomeIcon />} />
          <BottomNavigationAction
            label={t("cart")}
            icon={<ShoppingCartIcon />}
          />
          {/* <BottomNavigationAction label={t("signUp")} icon={<FavoriteIcon />} /> */}
          <BottomNavigationAction label={t("account1")} icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
