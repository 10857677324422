import React from "react";
import { ArrowRight } from "@material-ui/icons";
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";

const CategoryItems = ({ item }) => {
  const location = useLocation();
  const pathlen = location.pathname.split("/");

  return (
    <>
      <div className="li">
        {item.title} <ArrowRight />
        <div className="subMenu2">
          {item.Menus.map((item, index) => {
            return (
              <div className="li" key={index}>
                <Link
                  to={
                    pathlen === 2
                      ? `products/${item.links}`
                      : `../../products/${item.links}`
                  }
                  key={index}
                >
                  {item.subTitile}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CategoryItems;
